import { Component, OnInit } from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import { ContainerizeKubernetesDevService } from '../services/containerize-kubernetes-dev.service';
import {AddApplicationDialogComponent} from './add-application-dialog/add-application-dialog.component'
import {DeployCloudBucketDialogComponent} from './deploy-cloud-bucket-dialog/deploy-cloud-bucket-dialog.component'
import {ConfirmDeleteComponent} from '../confirm-delete/confirm-delete.component'
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RoleService } from '../services/role.service';
import { ContainerizeKubernetesConfigService } from '../services/containerize-kubernetes-config.service';
import {Sort} from '@angular/material/sort';
import { AddMembersDialogComponent } from './add-members-dialog/add-members-dialog.component';
import { saveAs } from 'file-saver';
export interface PeriodicElement {
  appid: string;
  name: string;
  usesdb: boolean;
  checked?: boolean;
  runtime: string;
  port: number;
  conditionCodeStr: string;
  url: string;
}


const ELEMENT_DATA: PeriodicElement[] = [
];

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit {
  loading = true;
  stopLoading = false;
  runLoading = false;
  displayedColumns: string[] = ['appid', 'name', 'venture', 'usesdb', 'runtime', 'port', 'conditionCodeStr', 'url', 'actions'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  total = 0;
  start = 0;
  limit = 10; 
  pageIndex = 0;
  search = "";
  sortd = false;
  sortby = null;
  ventures = []
  dashboardVenture = null;
  isAdmin = false;
  private subscription: Subscription;

  constructor(private devService: ContainerizeKubernetesDevService, 
    public dialog: MatDialog, 
    private _snackBar: MatSnackBar, 
    private router: Router,
    private roleService: RoleService,
    private activatedRoute : ActivatedRoute,
    private config: ContainerizeKubernetesConfigService) {
      this.dashboardVenture = config.venture;
    }

    configTable() {
      if(this.dashboardVenture && this.isAdmin) {
        this.displayedColumns = ['select', 'appid', 'name', 'usesdb', 'runtime', 'port', 'conditionCodeStr', 'url', 'actions'];
      } else if(this.dashboardVenture) {
        this.displayedColumns = ['appid', 'name', 'usesdb', 'runtime', 'port', 'conditionCodeStr', 'url', 'actions'];
      } else if(!this.dashboardVenture && this.isAdmin) {
        this.displayedColumns = ['select', 'appid', 'name','venture', 'usesdb', 'runtime', 'port', 'conditionCodeStr', 'url', 'actions'];
      }
    }

  async getApps() {
    const res = await this.devService.getAppList({
      start: this.start, 
      limit: this.limit, 
      search: this.search,
      sortby: this.sortby,
      sortd: this.sortd
    })
    this.dataSource.data = res.applications;
    this.total = res.total;
    this.loading = false;
    this.stopLoading = false;
    this.runLoading = false; 
  }

  async getVentures() {
    const data = await this.devService.getVentures();
    this.ventures = data;
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.start = params['start']*1;
      this.limit = params['limit'] ? params['limit']*1 : 10;
      this.pageIndex = this.start === 0 ? 0 : this.start / this.limit
      this.getApps();
      this.getVentures();
    });
    this.isAdmin = this.roleService.roleIsAdmin
    this.configTable()
    this.subscription = this.roleService.roleChanged.subscribe((isAdmin) => {
      this.isAdmin = isAdmin
      this.getApps()
      this.configTable();
    });
    
  }

  openAddAppDialog(): void {
    const addAppDialogRef = this.dialog.open(AddApplicationDialogComponent);

    addAppDialogRef.afterClosed().subscribe(result => {
      this.getApps();
      if(result) {
        const deployAppDialogRef = this.dialog.open(DeployCloudBucketDialogComponent, {
          data: {
            applicationId: result
          }
        });
        
        deployAppDialogRef.afterClosed().subscribe(result => {
          if(result) {
            this.router.navigate(['applications', result])
          }
         
        });
      }
      
    });
  }
  openAddMemebersDialog(): void {
    const addMembersDialogRef = this.dialog.open(AddMembersDialogComponent, {
      data: {
        apps: this.selection.selected
      }
    });
  }
  openDeleteAppDialog(app): void {
    const deleteAppDialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        text: `Applications will be deleted, it will take some time.`
      }
    });

    deleteAppDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.devService.deleteAppById(app.appID).then(() => {
          this.getApps();
          this._snackBar.open(`${app.name} deleted!`, null, {
            duration: 2000,
          });
        }).catch(() => {
          alert('Something went wrong, refresh page and try again. Or contact to technical support.');
        });
      }
    });
  }

  reloadData() {
    this._snackBar.open('Data refreshed!', null, {
      duration: 2000,
    });
    this.getApps();
  }

  openDeployDialog(app): void {
    const deployAppDialogRef = this.dialog.open(DeployCloudBucketDialogComponent, {
      data: {
        applicationId: app.appID
      }
    });

    deployAppDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.router.navigate(['applications', result])
      }
    });
  }

  openInst(instID) {
    this.router.navigate([`applications`, instID])
  }

  handleStop(app) {
    this.stopLoading = true;
    // @ts-ignore
    if (app.running) {
      this.devService.stopApp(app.instID)
        .then(res => {
          this._snackBar.open(`${app.name} stopped!`, null, {
            duration: 2000,
          });
        });
    }
    setTimeout(() => this.getApps(), 5000);
  }

  handleRun(app) {
    this.runLoading = true;
    
    if (!app.running) {
      this.devService.runApp(app.instID)
        .then(res => {
          this._snackBar.open(`${app.name} started!`, null, {
            duration: 2000,
          });
        });
    }
    setTimeout(() => this.getApps(), 5000);
  }

  changePage(e) {
    this.start =  e.pageIndex !== this.pageIndex ? e.pageIndex * e.pageSize : this.start;
    if(e.pageSize !== this.limit && this.start % e.pageSize!==0) {
      this.start = 0;
    }
    this.limit = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.router.navigate(
      [], 
      {
        relativeTo: this.activatedRoute,
        queryParams: {start: this.start, limit: this.limit}, 
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  changeAppVenture(e, ventid) {
    this.devService.updateAppVenture(e.appID, ventid).then(_=>this.getApps()).catch(_=> this._snackBar.open(`Some error accures!`, null, {
      duration: 2000,
    }));
  }

  searchFunc() {
    this.getApps()
  }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.sortby = null;
      this.sortd = false;
      this.getApps();
      return;
    }
    this.sortd = sort.direction === 'desc';
    
    switch(sort.active) {
      case `appID`:
        this.sortby = `id`;
        break;
      case `usesDB`:
        this.sortby = `usesdb`;
        break;
      case `conditionCodeStr`:
        this.sortby = `status`;
        break;
      default:
        this.sortby = sort.active
    }
    this.getApps();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.appid}`;
  }

  downloadFile(data: any) {
    const replacer = (key, value) => value === null ? '' : value;
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], {type: 'text/csv' })
    saveAs(blob, "apps.csv");
  }
  prepareExportData(allApps) {
    let applications = []
    if(allApps && allApps.length){
      for(let i = 0; i < allApps.length; i++) {
        const inst = {...allApps[i], ...(allApps[i].instances && allApps[i].instances.length > 0 ? allApps[i].instances[0]: {})}
        delete inst.instances
        applications.push(inst)
      }
    }
    return applications;
  }

  async exportApps() {
    const data = await this.devService.getAppList({start: 0});
    const allApps = this.prepareExportData(data.applications)
    this.downloadFile(allApps);
  }
}
function AddMemebersDialogComponent(AddMemebersDialogComponent: any) {
  throw new Error('Function not implemented.');
}

