import { Component, OnInit, DoCheck, OnDestroy } from '@angular/core';
import { AuthService } from 'containerize-angular-identity-server';
import { authConfig } from '../config/auth.config'
import { Menus } from 'src/config';
import {RoleService} from '../libs/containerize-kubernetes/services/role.service'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, DoCheck, OnDestroy{
  version = '';
  menus = Menus
  isCollapsed = false;
  firstName;
  lastName;
  fullName;
  shortName;
  email;
  isAuth = false;
  differ: any;
  private subscription: Subscription;
  isAdmin = false;
  constructor(private authService: AuthService, private roleService: RoleService) {
    this.configure();
  }

  private configure() {
    this.authService.runInitialLoginSequence(authConfig);
  }

  signoutRedirect() {
    this.authService.logout();
    localStorage.clear();
    sessionStorage.clear();
  }

  setUserData() {
    this.firstName = this.authService.identityClaims['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname']
    this.lastName = this.authService.identityClaims['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
    this.fullName = this.firstName + ' ' + this.lastName
    this.shortName = this.firstName[0] + this.lastName[0]
    this.email = this.authService.identityClaims['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'];
  }


  async ngOnInit() {
    if(this.authService.identityClaims) {
      this.setUserData()
      this.isAuth = true;
      this.roleService.getRoleInfo();
      this.isAdmin = this.roleService.isAdmin;
      
    }
    this.subscription = this.roleService.roleChanged.subscribe((isAdmin) => {
      this.isAdmin = isAdmin;
    });
  }

  ngDoCheck() {
    if(this.authService.identityClaims && this.firstName !== this.authService.identityClaims['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname']) {
      this.setUserData()
      if(this.authService.identityClaims) {
        this.roleService.getRoleInfo();
        this.isAuth = true;
      } else {
        this.isAuth = false;
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
