
<mat-card>
    <mat-card-title class="page-card-title">
        Products
        <div class="page-card-actions">
          <button class="card-title-button" mat-raised-button color="primary" (click)="openAddModal()">New Product</button>
        </div>
    </mat-card-title>

<div class="overflow">
<table mat-table [dataSource]="dataSource" class="table">
    <!-- Name Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>  ID </th>
      <td mat-cell *matCellDef="let element">  {{element.id}} </td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element"> 
            <mat-icon (click)="$event.stopPropagation()" class="material-icons" aria-hidden="false" aria-label="more_actions" [matMenuTriggerFor]="menu">
                more_vert
            </mat-icon> 
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="deleteProductFamily(element)">Delete</button>
            </mat-menu>
        </td>
      </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"
        class="example-element-row">
    </tr>
  </table>
  <h3 class="table-empty--text" *ngIf="!loading && dataSource.data && dataSource.data.length === 0">No Data</h3>
  <mat-spinner *ngIf="loading" class="spinner"></mat-spinner>
</div>
</mat-card>