import { AuthConfig } from 'containerize-angular-identity-server';
import {identityServerClientId} from './consts'
export const authConfig: AuthConfig = {
 // URL of the Identity Provider
  issuer: 'https://id.containerize.com',

 // Login URL of the Identity Provider
  loginUrl: 'https://id.containerize.com/connect/authorize',

 // Login URL of the Identity Provider
  logoutUrl: 'https://id.containerize.com/connect/endsession',

 // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin,

 // URL of the SPA to redirect the user after silent refresh
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

 // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: identityServerClientId,

 // Turn off default clear hash
  clearHashAfterLogin: false
}