import { Injectable } from '@angular/core';
import axios from 'axios';

import * as jwt_decode from 'jwt-decode';
import { AuthService } from 'containerize-angular-identity-server';
import {ContainerizeKubernetesConfigService} from '../services/containerize-kubernetes-config.service'

@Injectable({
  providedIn: 'root'
})
export class IdentityServerApiService {
// @ts-ignore
  identityProviderUrl = ``;
  constructor(private authService: AuthService, private ccConfigService: ContainerizeKubernetesConfigService) { 
    this.identityProviderUrl = this.ccConfigService.identityServerUrl
  }

  getHeaders() {

    const id_token = sessionStorage.getItem('id_token');
    return {
      headers: {
        Authorization: `Bearer ${id_token}`,
      }
    };
  }
  getToken() {
    return axios.get(this.identityProviderUrl + '/api/token/get-permanent-token', this.getHeaders());
  }
  refreshToken() {
    return axios.post(this.identityProviderUrl + '/api/token/new-permanent-token', {},  this.getHeaders());
  }
  setTokenToLocalStorage(token: string) {
    localStorage.setItem('ccToken', token);
  }
  getTokenFromLocalStorage() {
    return localStorage.getItem('ccToken');
  }

  async getTokenOrGenerate() {
    if(this.getTokenFromLocalStorage()) {
      const token = this.getTokenFromLocalStorage()
      if(this.authService.identityClaims["PSP.UserId"] !==  this.decodeToken(token)["PSP.UserId"]) {
        const token = await this.getToken()
        this.setTokenToLocalStorage(token.data);
        return token.data
      }
      
      return this.getTokenFromLocalStorage()
    } else {
      const token = await this.getToken()
      this.setTokenToLocalStorage(token.data);
      return token.data
    }
  }

  decodeToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
}
