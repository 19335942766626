import {ContainerizeKubernetesMenu, ContainerizeKubernetesModule} from '../libs/containerize-kubernetes'

import {conholdateCloudIdentityServerUrl, devServiceProviderUrl} from './consts';

export const Menus = [
    // other imports,
    ...ContainerizeKubernetesMenu,
]

export const imports = [
     // other imports,
     ContainerizeKubernetesModule.forRoot({devServiceProviderUrl, identityServerUrl: conholdateCloudIdentityServerUrl, venture: 'aspose.app'}),
    // ContainerizeKubernetesModule.forRoot({devServiceProviderUrl, identityServerUrl: conholdateCloudIdentityServerUrl, venture: null}),
]

