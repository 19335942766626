import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ContainerizeKubernetesDevService } from '../services/containerize-kubernetes-dev.service';
@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss']
})
export class AddUserDialogComponent implements OnInit {
  createUserForm: FormGroup;
  token = new FormControl('');
  loading = false;
  isAdded = false;
  coppyActive = false;
  password = "";
  constructor(
    private devService: ContainerizeKubernetesDevService, 
    public dialog: MatDialog, 
    public dialogRef: MatDialogRef<AddUserDialogComponent>,
    fb: FormBuilder
    ) {
      this.createUserForm = fb.group({
        token: this.token,
      });
    }

  ngOnInit(): void {
  }

  add() {
    if(this.createUserForm.valid && !this.loading) {
      this.loading = true;
      this.devService.addUser(this.createUserForm.value).then(res => {
        const {data} = res
        const {password} = data 
        this.loading = false;
        this.isAdded = true;
        this.password = password
      })
    }
    
  }

  copyText() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.password;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.coppyActive = true;
    setTimeout(() => {
      this.coppyActive = false;
    }, 1000);
  }

}
