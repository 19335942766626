import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ContainerizeKubernetesDevService } from '../services/containerize-kubernetes-dev.service';
@Component({
  selector: 'app-add-product-dialog',
  templateUrl: './add-product-dialog.component.html',
  styleUrls: ['./add-product-dialog.component.scss']
})
export class AddProductDialogComponent implements OnInit {
  createProdForm: FormGroup;
  id = new FormControl('');
  loading = false;
  constructor(
    private devService: ContainerizeKubernetesDevService, 
    public dialog: MatDialog, 
    public dialogRef: MatDialogRef<AddProductDialogComponent>,
    fb: FormBuilder
    ) {
      this.createProdForm = fb.group({
        id: this.id,
      });
    }

  ngOnInit(): void {
  }

  add() {
    if(this.createProdForm.valid && !this.loading) {
      this.loading = true;
      this.devService.addProd(this.createProdForm.value).then(res => {
        const {data} = res
        const {message} = data
        this.dialogRef.close()
        this.loading = false;
      })
    }
    
  }

}
