export const ContainerizeKubernetesMenu = [
    {
        to: `/applications`,
        name: `Applications`,
        icon: `apps`
    },
    {
        to: `/api-credentials`,
        name: `Api Credentials`,
        icon: `lock`
    },
    {
        to: `/users`,
        name: `Users`,
        icon: `supervisor_account`,
        isAdmin: true
    },
    {
        to: `/products`,
        name: `Products`,
        icon: `work_outline`,
        isAdmin: true
    },
]