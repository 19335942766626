import { Injectable, OnDestroy } from '@angular/core';
import axios from 'axios';
import {IdentityServerApiService} from '../identity-server/identity-server-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContainerizeKubernetesConfigService } from './containerize-kubernetes-config.service';
import { RoleService } from './role.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContainerizeKubernetesDevService {
    devServiceProviderUrl = ``;
    venture = null;
    isAdmin = false;
    private subscription: Subscription;
  constructor(
    private ccIdentityService: IdentityServerApiService,
    private _snackBar: MatSnackBar,
    private ccAppsConfig: ContainerizeKubernetesConfigService,
    private roleService: RoleService) {
      this.devServiceProviderUrl = this.ccAppsConfig.devServiceProviderUrl;
      this.venture = this.ccAppsConfig.venture;
      this.isAdmin = this.roleService.isAdmin;
      this.subscription = this.roleService.roleChanged.subscribe((isAdmin) => {
        this.isAdmin = isAdmin;
      });
  }
    

  async getHeaders() {
    const id_token = await this.ccIdentityService.getTokenOrGenerate();
    return {
      headers: {
        Authorization: `Bearer ${id_token}`,
      }
    };
  }

  async getVersion() {
    return axios.get(this.devServiceProviderUrl + '/version', await this.getHeaders());
  }

  async getUserList(filter) {
    let path = this.devServiceProviderUrl + '/admin/users/list?'
    if(filter && typeof filter.start === "number") {
      path += `start=${filter.start}`;
    }
    if(filter && typeof filter.limit === "number") {
      path += `&limit=${filter.limit}`;
    }
    if(filter && filter.search) {
      path += `&search=${filter.search}`
    }
    if(filter && filter.sortby) {
      path += `&sortby=${filter.sortby}`
    }
    if(filter && filter.sortd) {
      path += `&sortd=DESC`
    }

    const res = await axios.get(path, await this.getHeaders())
    return res.data;
  }

  async getAppUserList(appid) {
    return axios.get(this.devServiceProviderUrl + `/admin/users/list?appid=${appid}`, await this.getHeaders())
  }


  async addUser({token}) {
    return axios.post(this.devServiceProviderUrl + '/admin/users/add', {token}, await this.getHeaders());
  }

  async attachUserToApp(data) {
    return axios.post(this.devServiceProviderUrl + '/admin/devapp/add', data, await this.getHeaders());
  }

  async unattachUserToApp(data) {
    return axios.post(this.devServiceProviderUrl + '/admin/devapp/delete', data, await this.getHeaders());
  }

  async attachUserToProd(data) {
    return axios.post(this.devServiceProviderUrl + `/admin/devprod?devid=${data.devid}&prodid=${data.prodid}`, null, await this.getHeaders());
  }

  async unattachUserToProd(data) {
    return axios.delete(this.devServiceProviderUrl + `/admin/devprod?devid=${data.devid}&prodid=${data.prodid}`, await this.getHeaders());
  }

  async getUserProdList(id) {
    let path = this.devServiceProviderUrl + `/admin/prods/list?userid=${id}`
    const res = await axios.get(path, await this.getHeaders())
    return res.data;
  }

  async getAllProds() {
    let path = this.devServiceProviderUrl + '/admin/prods/list'
    const res = await axios.get(path, await this.getHeaders())
    return res.data;
  }



  async getUserById(id) {
    try {    
        const res = await axios.get(`${this.devServiceProviderUrl}/admin/users/list?devid=${id}`, await this.getHeaders());
        const user = res && res.data && res.data.users && res.data.users.length > 0 ? res.data.users[0] : null;
        return user
    }
    catch(e) {
      console.error(e);
    }
  }

  

  async updateUserRole(user) {
    return axios.post(this.devServiceProviderUrl + '/admin/users/role', {
      devid: user.id,
      role: user.isadmin ? "dev" : "admin"
    }, await this.getHeaders());
  }

  async deleteUser(devid) {
    return axios.get(this.devServiceProviderUrl + '/admin/users/delete?devid=' + devid, await this.getHeaders());
  }

  async deployInst(data: any) {
    return axios.post(this.devServiceProviderUrl + '/deploy', {...data, force: true}, await this.getHeaders());
  }

  async getAppByAppID(appID: string) {
    if(this.isAdmin) 
      return axios.get(this.devServiceProviderUrl + `/admin/apps/list?appid=${appID}` , await this.getHeaders());

    return axios.post(this.devServiceProviderUrl + '/status', {appID}, await this.getHeaders());
  }

  async getLogsByAppID(appID: string) {
    return axios.post(this.devServiceProviderUrl + '/logs', {instid: appID}, await this.getHeaders());
  }

  async deleteAppById(appID: string) {
    return this.isAdmin ? axios.get(this.devServiceProviderUrl + '/admin/apps/delete?appid='+appID, await this.getHeaders()):
    axios.post(this.devServiceProviderUrl + '/unregister', {appID}, await this.getHeaders());
  }

  async stopApp(appID: string) {
    return axios.post(this.devServiceProviderUrl + '/stop', {instid: appID}, await this.getHeaders());
  }

  async getRuntimes() {
    return axios.get(this.devServiceProviderUrl + '/runtimes', await this.getHeaders());
  }

  async register(data: any) {
    return axios.post(this.devServiceProviderUrl + '/register', 
    data, await this.getHeaders());
  }

  async runApp(instid: string) {
    return axios.post(this.devServiceProviderUrl + '/start', {instid: instid}, await this.getHeaders());
  }

  async getDeveloperAppList(devId) {
    let preparePath = this.devServiceProviderUrl + '/admin/apps/list?devid=' + devId;

    if(this.venture) {
      preparePath += `&venture=${this.venture}`
    }
    const apps = await axios.get(preparePath, 
      await this.getHeaders())
    let applications = []
    if(apps.data.code === -1) {
      this._snackBar.open(`${apps.data.errors[0].message} contact to administrator!`, null, {
        duration: 10000,
      });
    } else if(apps.data.applications && apps.data.applications.length){
      for(let i = 0; i < apps.data.applications.length; i++) {
        const inst = {...apps.data.applications[i], ...(apps.data.applications[i].instances && apps.data.applications[i].instances.length > 0 ? apps.data.applications[i].instances[0]: {})}
        delete inst.instances
        applications.push(inst)
      }
    }
    return {
      ...apps.data,
      applications,
    };
  }

  async getAppList(filter) {
    let preparePath = this.devServiceProviderUrl + (this.isAdmin ? "/admin/apps/list?" : "/list?");
    if(filter && typeof filter.start === 'number') {
      preparePath += `start=${filter.start}`
    } 
    if(filter && typeof filter.limit === 'number') {
      preparePath += `&limit=${filter.limit}`
    } 
    if(filter && filter.devId) {
      preparePath += `&devId=${filter.devId}`
    }
    if(filter && filter.search) {
      preparePath += `&search=${filter.search}`
    }
    if(filter && filter.sortby) {
      preparePath += `&sortby=${filter.sortby}`
    }
    if(filter && filter.sortd) {
      preparePath += `&sortd=DESC`
    }
    if(this.venture) {
      preparePath += `&venture=${this.venture}`
    }
    


    const apps = await axios.get(preparePath, 
      await this.getHeaders())

    let applications = []
    if(apps.data.code === -1) {
      this._snackBar.open(`${apps.data.errors[0].message} contact to administrator!`, null, {
        duration: 10000,
      });
    } else if(apps.data.applications && apps.data.applications.length){
      for(let i = 0; i < apps.data.applications.length; i++) {
        const inst = {...apps.data.applications[i], ...(apps.data.applications[i].instances && apps.data.applications[i].instances.length > 0 ? apps.data.applications[i].instances[0]: {})}
        delete inst.instances
        applications.push(inst)
      }
    }
    
    return {
      ...apps.data,
      applications,
    };
  }


  async getProdList() {
    let path = this.devServiceProviderUrl + '/admin/prods/list'
    const res = await axios.get(path, await this.getHeaders())
    return res.data;
  }

  async addProd({id}) {
    return axios.post(this.devServiceProviderUrl + `/admin/prods?id=${id}`, null, await this.getHeaders());
  }

  async deleteProd(id) {
    return axios.delete(this.devServiceProviderUrl + `/admin/prods?id=${id}`, await this.getHeaders());
  }

  async getVentures() {
    const v = await axios.get(`${this.devServiceProviderUrl}/admin/vents/list`, 
        await this.getHeaders())
    return v.data && v.data.ventures ? v.data.ventures : []
  }

  async updateAppVenture(appid, ventid) {
    return axios.post(`${this.devServiceProviderUrl}/admin/apps/venture?appid=${appid}&ventid=${ventid}`, null, await this.getHeaders())
  }

 async getTemplates() {
    return axios.get(this.devServiceProviderUrl + '/kustomize-exp/list', await this.getHeaders());
 }

 async downloadAppTemplate(appID, template) {
    return axios.post(`${this.devServiceProviderUrl}/kustomize-exp?template=${encodeURIComponent(template)}`, {appID}, await this.getHeaders())
 }
}
