import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ContainerizeKubernetesDevService } from '../services/containerize-kubernetes-dev.service';
import { AddUserDialogComponent } from '../add-user-dialog/add-user-dialog.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UserDetailsComponent implements OnInit {
  user = null;
  loading = true;
  apploading = false;
  prodloading = false;
  appDisplayedColumns: string[] = ['appid', 'name', 'usesdb', 'runtime', 'port', 'conditionCodeStr', 'url'];
  proudctFamiliyDisplayedColumns: string[] = ['name', 'actions'];
  userId = null;
  addProductForm: FormGroup;
  productid = new FormControl('');
  filteredOptions: Observable<[]>;
  products = []
  appDataSource = new MatTableDataSource([]);
  productSource = new MatTableDataSource([]);
  constructor(private devService: ContainerizeKubernetesDevService, 
    public dialog: MatDialog, 
    private _snackBar: MatSnackBar, 
    private router: Router,
    private route: ActivatedRoute,
    fb: FormBuilder) {
                this.addProductForm = fb.group({
                  productid: this.productid,
                });

               }

  

  async prepareAutoComplite() {
    const res = await this.devService.getAllProds();
    this.products = res.products;
    this.filteredOptions = this.productid.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.id),
        map(id => id ? res.products.filter(option => option.id.toLowerCase().indexOf(id.toLowerCase()) === 0) : res.products.slice())
      );
  }
  async getUser() {
    this.user = await this.devService.getUserById(this.userId);
    console.log(this.user)
    this.loading = false;
  }

  displayFn(product): string {
    return product && product.id ? `${product.id}`: '';
  }


  addProd() {
    if(typeof this.productid.value === "object") {
      this.devService.attachUserToProd({devid: this.userId, prodid: this.productid.value.id})
      .then(_=> {
        this.getProds()
        this.productid.setValue("");
        this._snackBar.open(`Product was added!`, null, {
          duration: 2000,
        });
     
      })
      return;
    } else {
      for(let i = 0; i < this.products.length; i++) {
        if(this.products[i].id === this.productid.value) {
          this.devService.attachUserToProd({devid: this.userId, prodid: this.products[i].id}).then(_=> this.getProds())
          this.productid.setValue("");
          return;
        }
      }
      this._snackBar.open(`User does not exist!`, null, {
        duration: 2000,
      });

    }
  }

  ngOnInit() {
    
    this.route.params.subscribe((params: Params) => {
      this.userId = params.userId;
      this.apploading = true;
      this.prodloading = true;
      this.getUser();
      this.getApps();
      this.getProds();
      this.prepareAutoComplite();
    })
  }



  async getApps() {
    if(this.userId) {
      const res = await this.devService.getDeveloperAppList(this.userId)
      this.appDataSource.data = res.applications; 
    }
    this.apploading = false;
  }

  async getProds() {
    if(this.userId) {
      const res = await this.devService.getUserProdList(this.userId)
      this.productSource.data = !res.products ? [] : res.products;
    }
    this.prodloading = false;
  }



  openUser(id) {
    this.router.navigate([`users`, id])
  }


  async changeUserRole(user) {
    await this.devService.updateUserRole(user);
    this.getUser();
  }

  openInst(instID) {
    this.router.navigate([`applications`, instID])
  }

  deleteUser(user) {
    const deleteAppDialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        text: `User ${user.email} will be deleted.`
      }
    });
    deleteAppDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.devService.deleteUser(user.id).then(_=>  {
          this.getUser()
          this._snackBar.open(`${user.email} deleted!`, null, {
            duration: 2000,
          });
        })
        .catch(() => {
          alert('Something went wrong, refresh page and try again. Or contact to technical support.');
        });
      }
    });
  }

  unattachProductFamily (data) {
    this.devService.unattachUserToProd({devid: this.userId, prodid: data.id}).then(_=> this.getProds())
  }

}
