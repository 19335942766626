import { Injectable } from '@angular/core';
import {AuthService} from 'containerize-angular-identity-server'
import {IdentityServerApiService} from '../identity-server/identity-server-api.service';
import { ContainerizeKubernetesConfigService } from './containerize-kubernetes-config.service';
import axios from 'axios';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RoleService {
  roleChanged = new Subject<boolean>();
  roleIsAdmin = false;
  devServiceProviderUrl;
  constructor(private authService: AuthService, 
    private ccIdentityService: IdentityServerApiService,
    private ccAppsConfig: ContainerizeKubernetesConfigService,) { 
      this.devServiceProviderUrl = this.ccAppsConfig.devServiceProviderUrl;
  }

  async getHeaders() {
    const id_token = await this.ccIdentityService.getTokenOrGenerate();
    return {
      headers: {
        Authorization: `Bearer ${id_token}`,
      }
    };
  }

  get isAdmin() {
    return this.roleIsAdmin;
  }

  async getRoleInfo() {
    try {
      if(this.authService.identityClaims["PSP.UserId"]) {
        const devId = this.authService.identityClaims["PSP.UserId"];      
        const res = await axios.get(`${this.devServiceProviderUrl}/admin/users/list?devid=${devId}`, await this.getHeaders());
        this.roleIsAdmin = res && res.data && res.data.users && res.data.users.length > 0 && res.data.users[0].isadmin;
        this.roleChanged.next(this.roleIsAdmin);
      }
    }
    catch(e) {
      console.error(e);
    }
  }
}
