<div fxLayout="column" fxLayoutGap="20px">
  <mat-card>
    <mat-card-title class="page-card-title">
        Application: {{app &&  app.name }}
        
        <div class="page-card-actions">
          <button class="card-title-button" mat-raised-button (click)="reloadData()" matTooltip="Refresh Data">
            <mat-icon class="material-icons" aria-hidden="false" aria-label="more_actions">
                refresh
            </mat-icon> 
          </button>
          <!-- <button button type="secondary" *ngIf="!autoreload" (click)="setAutoReload()">Turn On Auto Reload</button>
          <button button type="secondary" *ngIf="autoreload" (click)="setAutoReload()">Turn Off Auto Reload</button> -->
          <button class="card-title-button" *ngIf="showLogs" mat-raised-button (click)="openDeployDialog()"> {{app && app.instID ? "Redeploy": "Deploy"}}</button>
          <button class="card-title-button" mat-raised-button *ngIf="app&&app.running" (click)="onStop()">Stop</button>
          <button  class="card-title-button" mat-raised-button color="primary" *ngIf="app&&app.condition===6" (click)="onRun()">Run</button>
          <button class="card-title-button" mat-raised-button color="warn" (click)="openDeleteAppDialog()">Delete</button>
        </div>
    </mat-card-title>
    <mat-card-content>
  
  
      

      <div class="custom-list">
        <div class="custom-list--item mobile-none">
            <div class="custom-list--header">
              Parameter
            </div>
            <div class="custom-list--header">
              Value
            </div>
        </div>
        <div class="custom-list--item">
          <div class="custom-list--key">
            Status
          </div>
          <div class="custom-list--value">
            {{app&&app.conditionCodeStr ? app.conditionCodeStr : 'Not deployed'}}
          </div>
        </div>
        <div class="custom-list--item">
          <div class="custom-list--key">
            ID
          </div>
          <div class="custom-list--value">
            {{app&&app.appID}}
          </div>
        </div>
        <div class="custom-list--item">
          <div class="custom-list--key">
            URL
          </div>
          <div class="custom-list--value">
            <a href="{{app&&app.url}}" class="link" target="_blank">{{app&&app.url}}</a>
          </div>
        </div>
        <div class="custom-list--item" *ngIf="app && app.repository && app.repository!==''">
          <div class="custom-list--key">
            Repository
          </div>
          <div class="custom-list--value">
            <a href="{{app&&app.repository}}" class="link" target="_blank"> {{app&&app.repository}}</a>
          </div>
        </div>
        <div class="custom-list--item" *ngIf="app && app.image && app.image!==''">
          <div class="custom-list--key">
            Image
          </div>
          <div class="custom-list--value">
            {{app&&app.image}}
          </div>
        </div>
        <div class="custom-list--item">
          <div class="custom-list--key">
            Port
          </div>
          <div class="custom-list--value">
            {{app&&app.port}}
          </div>
        </div>
        <div class="custom-list--item">
          <div class="custom-list--key">
            Created
          </div>
          <div class="custom-list--value">
            {{app&&app.created | date}}
          </div>
        </div>
      </div>
  
  </mat-card-content>
  </mat-card>
  


  
  <mat-card *ngIf="showLogs">
  
    <mat-card-title class="page-card-title">
       Application logs
        <mat-icon class="material-icons nav-icon" aria-hidden="false" aria-label="more_actions" *ngIf="logging" (click)="setLogging()">
          pause
        </mat-icon> 
        <mat-icon class="material-icons nav-icon" aria-hidden="false" aria-label="more_actions" *ngIf="!logging"  (click)="setLogging()">
          play_circle_outline
        </mat-icon> 
        <mat-icon class="material-icons nav-icon" aria-hidden="false" aria-label="more_actions" (click)="coppyLogs()">
          file_copy
        </mat-icon> 
    </mat-card-title>
    <div class="logs-wrapper">
      <div class="logs" #scrollMe><div [innerHtml]="logs"></div><div class="loading-dots" *ngIf="app&&!(app.conditionStr === 'Stopped' || app.conditionStr === 'Running' || app.conditionStr === 'Failed')"></div></div>
      <div class="text-copied" [ngClass]="{'active': coppyActive}">Copied!</div>
    </div>
    
    
  </mat-card>

  <mat-card>
    <mat-card-title class="page-card-title">
      Download Deployment
    </mat-card-title>
    <form [formGroup]="templatesForm" (ngSubmit)="downloadTemplate()">
      <mat-form-field appearance="outline">
        <mat-label>Templates</mat-label>
        <mat-select [formControl]="template">
          <mat-option *ngFor="let t of templates" [value]="t">
            {{t}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button class="add-button" mat-raised-button color="primary">Download</button>
    </form>
    
    
  </mat-card>


<mat-card  *ngIf="isAdmin">
  <mat-card-title class="page-card-title">
      Members
  </mat-card-title>

  <form [formGroup]="addMemberForm" (ngSubmit)="addUser()">
    <mat-form-field appearance="outline" class="fieldStyle">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Enter email" [formControl]="email" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option.email}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="email.hasError('required')">Email is <strong>required</strong></mat-error>
    </mat-form-field>
    <button class="add-button" mat-raised-button color="primary">Add</button>
  </form>
<div class="overflow">
<table mat-table [dataSource]="dataSource" class="table">

  <!-- ID Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef> ID </th>
    <td mat-cell *matCellDef="let element" class="colored-link"> {{element.id}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> First Name </th>
    <td mat-cell *matCellDef="let element">  {{element.name}} </td>
  </ng-container>
   <!-- Surname Column -->
   <ng-container matColumnDef="surname">
    <th mat-header-cell *matHeaderCellDef> Last Name </th>
    <td mat-cell *matCellDef="let element" >  {{element.surname}} </td>
  </ng-container>


  <!-- Email Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> Email </th>
    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
  </ng-container>
  <!-- Actions Column -->
  <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef>  </th>
      <td mat-cell *matCellDef="let element"> 
          <mat-icon class="material-icons" aria-hidden="false" aria-label="more_actions" [matMenuTriggerFor]="menu">
              more_vert
          </mat-icon> 
          <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="deleteUser(element.id)">Unattach</button>
          </mat-menu>
      </td>
    </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      (click)="selection.toggle(row)">
  </tr>
</table>
<mat-spinner *ngIf="usersloading" class="spinner"></mat-spinner>
<h3 class="table-empty--text" *ngIf="!usersloading && dataSource.data && dataSource.data.length === 0">No Data</h3>
</div>
</mat-card>
  
  </div>