
<mat-card class="no-padding">
  <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
    <div fxFlex="33" class="user-card user-aside">
      <!-- <mat-card-title class="page-card-title">
        Users
      </mat-card-title> -->
      <div class="profile">
        <span class="profile-avatar">{{user && user.name && user.name[0]}}{{user && user.surname && user.surname[0]}}</span>
        <h3>{{user && user.name}} {{user && user.surname}}</h3>
      </div> 

      <div class="custom-list">
        <div class="custom-list--item">
          <div class="custom-list--key">
            Email
          </div>
          <div class="custom-list--value">
            {{user && user.email}}
          </div>
        </div>
        <div class="custom-list--item">
          <div class="custom-list--key">
            ID
          </div>
          <div class="custom-list--value">
            {{user && user.id}}
          </div>
        </div>
        <div class="custom-list--item">
          <div class="custom-list--key">
            Role
          </div>
          <div class="custom-list--value">
            {{user && user.isadmin ? 'Admin' : 'Developer'}}
          </div>
        </div>
        <div class="custom-list--item">
          <div class="custom-list--key">
            ES Account
          </div>
          <div class="custom-list--value">
            {{user && user.elasticname ? user.elasticname : 'No'}}
          </div>
        </div>
      </div>

      <mat-card-title class="page-card-title">
        Products
      </mat-card-title>
      <form [formGroup]="addProductForm" (ngSubmit)="addProd()">
        <mat-form-field appearance="outline" class="fieldStyle">
            <mat-label>Product ID</mat-label>
            <input matInput placeholder="Enter Product ID" [formControl]="productid" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.id}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="productid.hasError('required')">Product ID is <strong>required</strong></mat-error>
        </mat-form-field>
        <button class="add-button" mat-raised-button color="primary">Add</button>
      </form>
      <div>

      
      <table mat-table [dataSource]="productSource" class="table-products">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>  ID </th>
          <td mat-cell *matCellDef="let element">  {{element.id}} </td>
        </ng-container>
        <!-- Actions Column -->
        <ng-container matColumnDef="actions" stickyEnd>
            <th mat-header-cell *matHeaderCellDef>  </th>
            <td mat-cell *matCellDef="let element"> 
                <mat-icon (click)="$event.stopPropagation()" class="material-icons" aria-hidden="false" aria-label="more_actions" [matMenuTriggerFor]="menu">
                    more_vert
                </mat-icon> 
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="unattachProductFamily(element)">Unattach</button>
                </mat-menu>
            </td>
          </ng-container>
        <tr mat-header-row *matHeaderRowDef="proudctFamiliyDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: proudctFamiliyDisplayedColumns;"
            class="example-element-row">
        </tr>
      </table>
      <h3 class="table-empty--text" *ngIf="!prodloading && productSource.data && productSource.data.length === 0">No Data</h3>
      <mat-spinner *ngIf="prodloading" class="spinner"></mat-spinner>
    </div>
    </div>
    <div fxFlex="67" class="user-card user-aside">
      <div class="overflow">
        <mat-card-title class="page-card-title">
          Applications
        </mat-card-title>
        <table mat-table [dataSource]="appDataSource" class="table">

         <!-- ID Column -->
           <ng-container matColumnDef="appid">
             <th mat-header-cell *matHeaderCellDef> ID </th>
             <td mat-cell *matCellDef="let element" class="colored-link" (click)="openInst(element.appID)"> {{element.appID}} </td>
           </ng-container>
         
           <!-- Name Column -->
           <ng-container matColumnDef="name">
             <th mat-header-cell *matHeaderCellDef> Name </th>
             <td mat-cell *matCellDef="let element" (click)="openInst(element.appID)" class="colored-link">  {{element.name}} </td>
           </ng-container>
         
           <!-- isUseDB Column -->
           <ng-container matColumnDef="usesdb">
             <th mat-header-cell *matHeaderCellDef> Use Database </th>
             <td mat-cell *matCellDef="let element"> {{element.usesDB ? 'Yes' : 'No'}} </td>
           </ng-container>

           <!-- Runtime Column -->
           <ng-container matColumnDef="runtime">
             <th mat-header-cell *matHeaderCellDef> Runtime </th>
             <td mat-cell *matCellDef="let element"> {{element.runtime}} </td>
           </ng-container>

           <!-- Port Column -->
           <ng-container matColumnDef="port">
             <th mat-header-cell *matHeaderCellDef> Port </th>
             <td mat-cell *matCellDef="let element"> {{element.port}} </td>
           </ng-container>

           <!-- Status Column -->
           <ng-container matColumnDef="conditionCodeStr">
             <th mat-header-cell *matHeaderCellDef> Status </th>
             <td mat-cell *matCellDef="let element"> {{element.conditionCodeStr}} </td>
           </ng-container>

           <!-- Url Column -->
           <ng-container matColumnDef="url">
             <th mat-header-cell *matHeaderCellDef> Url </th>
             <td mat-cell *matCellDef="let element"> <a class="link" href="{{element.url}}" color="primary" target="_blank">{{element.url}}</a></td>
           </ng-container>
         
       
         <tr mat-header-row *matHeaderRowDef="appDisplayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: appDisplayedColumns;"></tr>
        
       </table>

      <h3 class="table-empty--text" *ngIf="!apploading && appDataSource.data && appDataSource.data.length === 0">No Data</h3>
      <mat-spinner *ngIf="apploading" class="spinner"></mat-spinner>
    </div>
    </div>
  </div>
   
      

</mat-card>