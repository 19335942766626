<div *ngIf="!isAdded">
    <h2 mat-dialog-title>New User</h2>
    <mat-dialog-content class="mat-typography" >
        <form [formGroup]="createUserForm">
            <mat-form-field appearance="outline" class="fieldStyle">
                <mat-label>Token</mat-label>
                <textarea matInput placeholder="Enter token of the user" [formControl]="token" required rows="6"></textarea>
                <mat-error *ngIf="token.hasError('required')">Token is <strong>required</strong></mat-error>
            </mat-form-field>
        </form>
    </mat-dialog-content>
    
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button (click)="add()" cdkFocusInitial>Add</button>
    </mat-dialog-actions>

</div>

<div *ngIf="isAdded">
    <h2 mat-dialog-title>New User ES Password</h2>
    <p>Copy the password and send to user that was added</p>
    <mat-dialog-content class="mat-typography" >
        <div class="token-content" (click)="copyText()">
            {{password && password!=='' ? password : 'Password will be shown here.'}}
            <div class="text-copied" [ngClass]="{'active': coppyActive}">Copied!</div>
            <div class="copy-button">
                <svg viewBox="64 64 896 896" fill="currentColor" width="1em" height="1em" data-icon="copy" aria-hidden="true"><path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z"></path></svg>
            </div>
        </div> 
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
      </mat-dialog-actions>
</div>
