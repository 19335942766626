
<mat-card>
    <mat-card-title class="page-card-title">
        Applications
        <div class="page-card-actions">

          <button mat-raised-button *ngIf="selection.hasValue()" [matMenuTriggerFor]="menuAc">
            More Actions
          </button>
        <mat-menu #menuAc="matMenu">
            <button mat-menu-item *ngIf="isAdmin"  (click)="openAddMemebersDialog()">Add Members</button>
        </mat-menu>
          <mat-form-field  class="search">
            <mat-label>Search</mat-label>
            <input matInput placeholder="Search by id or name" [(ngModel)]="search" (ngModelChange)="searchFunc()">
          </mat-form-field>

          <button class="card-title-button" mat-raised-button (click)="reloadData()" matTooltip="Refresh Data">
              <mat-icon class="material-icons" aria-hidden="false" aria-label="more_actions">
                  refresh
              </mat-icon> 
          </button>
          <button class="card-title-button" mat-raised-button (click)="exportApps()">Export to CSV</button>
          <button class="card-title-button" mat-raised-button color="primary" (click)="openAddAppDialog()">New Application</button>
        </div>
    </mat-card-title>
<div class="overflow">
<table mat-table [dataSource]="dataSource" matSort class="table" (matSortChange)="sortData($event)">
    <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row" click="selection.toggle(row);">
      <mat-checkbox (click)="$event.stopPropagation();"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)"
                    [aria-label]="checkboxLabel(row)">
      </mat-checkbox>
    </td>
  </ng-container>
    <!-- ID Column -->
    <ng-container matColumnDef="appid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="appID"> ID </th>
      <td mat-cell *matCellDef="let element" class="colored-link" (click)="openInst(element.appID)"> {{element.appID}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="name"> Name </th>
      <td mat-cell *matCellDef="let element" (click)="openInst(element.appID)" class="colored-link">  {{element.name}} </td>
    </ng-container>

     <!-- Venture Column -->
     <ng-container matColumnDef="venture" *ngIf="!dashboardVenture">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="venture"> Venture </th>
      <td mat-cell *matCellDef="let element" class="venture"> 
        {{element.venture }}
        <mat-icon class="material-icons venture-icon" aria-hidden="false" aria-label="more_actions" [matMenuTriggerFor]="menuVenture">
          keyboard_arrow_down
        </mat-icon>
        <mat-menu #menuVenture="matMenu">
          <div *ngFor="let v of ventures">
            <button  *ngIf="v.id"  mat-menu-item (click)="changeAppVenture(element, v.id)">{{v.id}}</button>
          </div>
          <!-- <button mat-menu-item (click)="changeAppVenture(element, null)">No Venture</button> -->
           
        </mat-menu>
      </td>
    </ng-container>

  
    <!-- isUseDB Column -->
    <ng-container matColumnDef="usesdb">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="usesDB"> Use Database </th>
      <td mat-cell *matCellDef="let element"> {{element.usesDB ? 'Yes' : 'No'}} </td>
    </ng-container>

    <!-- Runtime Column -->
    <ng-container matColumnDef="runtime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="runtime"> Runtime </th>
      <td mat-cell *matCellDef="let element"> {{element.runtime}} </td>
    </ng-container>

    <!-- Port Column -->
    <ng-container matColumnDef="port">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="port"> Port </th>
      <td mat-cell *matCellDef="let element"> {{element.port}} </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="conditionCodeStr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="conditionCodeStr"> Status </th>
      <td mat-cell *matCellDef="let element"> {{element.conditionCodeStr}} </td>
    </ng-container>

     <!-- Url Column -->
     <ng-container matColumnDef="url">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="url"> Url </th>
      <td mat-cell *matCellDef="let element"> <a class="link" href="{{element.url}}" color="primary" target="_blank">{{element.url}}</a></td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element"> 
            <mat-icon class="material-icons" aria-hidden="false" aria-label="more_actions" [matMenuTriggerFor]="menu">
                more_vert
            </mat-icon> 
            <mat-menu #menu="matMenu">
                <button *ngIf="!element.instID" mat-menu-item (click)="openDeployDialog(element)">Deploy Instance</button>
                <button *ngIf="element.instID" mat-menu-item (click)="openDeployDialog(element)">Redeploy Instance</button>
                <button *ngIf="element.condition === 6" mat-menu-item (click)="handleRun(element)">Run App</button>
                <button *ngIf="element.condition === 5" mat-menu-item (click)="handleStop(element)">Stop App</button>
                <button mat-menu-item (click)="openDeleteAppDialog(element)">Delete App</button>
            </mat-menu>
        </td>
      </ng-container>
  
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        >
    </tr>
  </table>
  <mat-paginator [pageIndex]="pageIndex" *ngIf="dataSource.data && dataSource.data.length > 0"  [length]="total"
    [pageSize]="limit" (page)="changePage($event)" [pageSizeOptions]="[5, 10, 25, 50, 100]"></mat-paginator>

  <mat-spinner *ngIf="loading" class="spinner"></mat-spinner>
  <h3 class="table-empty--text" *ngIf="!loading && dataSource.data && dataSource.data.length === 0">No Data</h3>
</div>
</mat-card>