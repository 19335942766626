import { Component, OnInit, Inject, ViewChild, NgZone } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { ContainerizeKubernetesDevService } from '../../services/containerize-kubernetes-dev.service';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import {MatChipInputEvent} from '@angular/material/chips';

export interface DialogData {
  applicationId: number;
}

@Component({
  selector: 'app-deploy-cloud-bucket-dialog',
  templateUrl: './deploy-cloud-bucket-dialog.component.html',
  styleUrls: ['./deploy-cloud-bucket-dialog.component.scss']
})
export class DeployCloudBucketDialogComponent implements OnInit {
  createCloudBucketForm: FormGroup;
  radioValue = `2`;
  repository = new FormControl('');
  entrypoint = new FormControl('');
  buildRoot = new FormControl('');
  runtime = new FormControl(``);
  port = new FormControl("3000");
  appid = new FormControl(-1);
  image = new FormControl('');
  hostOS = new FormControl('');
  loading = false;
  runtimes = [];
  appInst;
  type = new FormControl('');
  envvars = [
    {key: ``, value: ``}
  ]
  

  healthChecksNumber = []
  probTypes = ['startup', 'liveness', 'readiness'];
  probes = {
    startup: {
      active: false,
      http: {
        path: "",
        port: "",
        headers: []
      },
      initialDelaySeconds: null,
      periodSeconds: null,
      timeoutSeconds: null,
      successThreshold: null,
      failureThreshold: null
    },
    liveness: {
      active: false,
      http: {
        path: "",
        port: "",
        headers: []
      },
      initialDelaySeconds: null,
      periodSeconds: null,
      timeoutSeconds: null,
      successThreshold: null,
      failureThreshold: null
    },
    readiness: {
      active: false,
      http: {
        path: "",
        port: "",
        headers: []
      },
      initialDelaySeconds: null,
      periodSeconds: null,
      timeoutSeconds: null,
      successThreshold: null,
      failureThreshold: null
    }
  }

  // Proxy
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  proxy = {
    configuration: {
      nginx: ''
    },
    entrypoints: []
  }
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  constructor(
    private devService: ContainerizeKubernetesDevService,
    public dialogRef: MatDialogRef<DeployCloudBucketDialogComponent>,
    @Inject(MAT_DIALOG_DATA) 
    public data: DialogData,
    private fb: FormBuilder,
    private _ngZone: NgZone) {
      this.appid.setValue(data.applicationId)
      this.createCloudBucketForm = this.fb.group({
        image: this.image,
        port: this.port,
        appid: this.appid,
        hostOS: this.hostOS
      });
    }

  removeEnvvar(i) {
    this.envvars.splice(i, 1)
  }

  removeHeader(type, i) {
    this.probes[type].http.headers.splice(i, 1)
  }

  addEnvvar() {
    this.envvars.push({
      key: '',
      value: ''
    })
  }

  addHeader(type) {
    this.probes[type].http.headers.push({
      name: '',
      value: ''
    })
  }

  addHealthCheck() {
    this.healthChecksNumber.push(this.healthChecksNumber.length+1); 
  }

  getRuntimes() {
    this.devService.getRuntimes().then((res: any) => this.runtimes = res.data.runtimes );
  }

  async getApp() {
    let {data} = await this.devService.getAppByAppID(this.appid.value);
    if(data && data.applications &&  data.applications.length >0 && 
      data.applications[0].instances && data.applications[0].instances.length > 0) {
        this.appInst = data.applications[0].instances[0]
        if(this.appInst.repository) this.repository.setValue(this.appInst.repository)
        if(this.appInst.entrypoint) this.entrypoint.setValue(this.appInst.entrypoint)
        if(this.appInst.buildRoot) this.buildRoot.setValue(this.appInst.buildRoot)
        if(this.appInst.runtime) this.runtime.setValue(this.appInst.runtime)
        if(this.appInst.port) this.port.setValue(`${this.appInst.port}`)
        if(this.appInst.image) this.image.setValue(this.appInst.image)
        if(this.appInst.hostOSCodeStr) this.hostOS.setValue(this.appInst.hostOSCodeStr)
        if(this.appInst.envvars && this.appInst.envvars.length > 0) {
          this.envvars = this.appInst.envvars.map(env => {
            return {
              key: env.substr(0,env.indexOf('=')),
              value: env.substr(env.indexOf('=')+1)
            }
          })
        }
        if(this.appInst.probes) {
          Object.keys(this.appInst.probes).map(key => {
            this.probes[key].active = true
            if(this.appInst.probes[key].http.headers && 
              this.appInst.probes[key].http.headers.length > 0) this.probes[key].http.headers = this.appInst.probes[key].http.headers;
            this.probes[key].http.port = `${this.appInst.probes[key].http.port}`;
            this.probes[key].http.path = this.appInst.probes[key].http.path ? this.appInst.probes[key].http.path : "";
            if(this.appInst.probes[key].initialDelaySeconds) this.probes[key].initialDelaySeconds = this.appInst.probes[key].initialDelaySeconds;
            if(this.appInst.probes[key].periodSeconds) this.probes[key].periodSeconds = this.appInst.probes[key].periodSeconds;
            if(this.appInst.probes[key].timeoutSeconds) this.probes[key].timeoutSeconds = this.appInst.probes[key].timeoutSeconds;
            if(this.appInst.probes[key].successThreshold) this.probes[key].successThreshold = this.appInst.probes[key].successThreshold;
            if(this.appInst.probes[key].failureThreshold) this.probes[key].failureThreshold = this.appInst.probes[key].failureThreshold;
          })
        }

        if(this.appInst.proxy) {
          if(this.appInst.proxy.configuration && this.appInst.proxy.configuration.nginx) this.proxy.configuration = this.appInst.proxy.configuration;
          if(this.appInst.proxy.entrypoints && this.appInst.proxy.entrypoints.length > 0) this.proxy.entrypoints = this.appInst.proxy.entrypoints
        }

        if(this.appInst.repository && this.appInst.repository.length > 0) {
          this.radioValue = "1"
          this.onChange()
        }
    }
  }

  ngOnInit(): void {
    this.getRuntimes()
    this.getApp()
    
  }

  onChange() {
    if(this.radioValue ==='1') {
      this.createCloudBucketForm = this.fb.group({
        repository: this.repository,
        entrypoint: this.entrypoint,
        runtime: this.runtime,
        port: this.port,
        appid: this.appid,
        buildRoot: this.buildRoot,
      });
    } else {
      this.createCloudBucketForm = this.fb.group({
        image: this.image,
        port: this.port,
        appid: this.appid,
        hostOS: this.hostOS
      });
    }
  }

  validateProbes() {
    let check = this.probTypes.map(type => 
      this.probes[type].active && this.probes[type].http.path ==="")
    return check.indexOf(true) === -1
  }

  prepareProbes() {
    let activeProbTypes =  this.probTypes.filter(type => this.probes[type].active)
    const probes = {}
    activeProbTypes.map(type => {
      let prob = this.probes[type]
      delete prob.active
      if(prob.http.headers.length === 0) delete prob.http.headers
      if(!prob.http.port) delete prob.http.port
      if(!prob.initialDelaySeconds) delete prob.initialDelaySeconds
      if(!prob.periodSeconds) delete prob.periodSeconds
      if(!prob.timeoutSeconds) delete prob.timeoutSeconds
      if(!prob.successThreshold) delete prob.successThreshold
      if(!prob.failureThreshold) delete prob.failureThreshold
      probes[type] = prob
      return prob      
    })

    return probes;
  }

  prepareEntrypointPaths() {
    this.proxy.entrypoints.map((e, i) => e.paths.map((p, j) => {
      if(p.from === ``) this.removeEntrypointPath(i, j)
    }))
  }

  prepareProxy() {
    let proxy = null;
    if(this.proxy.configuration.nginx.length > 0 || 
      this.proxy.entrypoints.length > 0) {
        proxy = {configuration: this.proxy.configuration}
        this.prepareEntrypointPaths()
        let entrypoints = this.proxy.entrypoints.length > 0 ? this.proxy.entrypoints.filter(item => item.host !== '' &&  item.paths.length > 0) : null
        if(entrypoints) proxy.entrypoints = entrypoints
        return proxy
    }
    return null
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  addEntrypoint() {
    this.proxy.entrypoints.push({host: '', paths: [{from: ``, to: ``}]})
  }

  removeEntrypoint(i) {
    this.proxy.entrypoints.splice(i, 1)
  }

  addEntrypointPath(entrypointIndex) {
    this.proxy.entrypoints[entrypointIndex].paths.push({from: ``, to: ``})
  }

  removeEntrypointPath(entrypointIndex, pathIndex) {
    this.proxy.entrypoints[entrypointIndex].paths.splice(pathIndex, 1)
  }

  save() {
    if(this.createCloudBucketForm.valid && !this.loading && this.validateProbes()) {

      // prepare envvars
      let envvars = []
      for(let i = 0; i < this.envvars.length; i++) {
        if(this.envvars[i].key!==``&&this.envvars[i].value !==``) {
          envvars.push(`${this.envvars[i].key}=${this.envvars[i].value}`)
        }
      } 

      // prepare probes
      let probes = this.prepareProbes()
      this.loading = true
      let sendData = {...this.createCloudBucketForm.value, envvars}
      if(Object.keys(probes).length > 0) sendData.probes = probes

      // prepare proxy
      let proxy = this.prepareProxy();
      if(proxy) sendData.proxy = proxy;

      this.devService.deployInst(sendData).then(res => {
        const {data} = res
        const {message} = data
        const id = message.slice(message.indexOf("'") + 1, message.lastIndexOf("'"))
        this.dialogRef.close(id)
        this.loading = false;
      })
    }
  }
  



}
