<h2 mat-dialog-title>New Product</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="createProdForm">
        <mat-form-field appearance="outline" class="fieldStyle">
            <mat-label>ID</mat-label>
            <input matInput placeholder="Enter id of the product" [formControl]="id" required>
            <mat-error *ngIf="id.hasError('required')">ID is <strong>required</strong></mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="add()" cdkFocusInitial>Add</button>
</mat-dialog-actions>