import { CommonModule } from '@angular/common'; 
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import {IdentityServerApiService} from './identity-server/identity-server-api.service';
import { ContainerizeKubernetesRoutingModule } from './containerize-kubernetes-routing.module';
import { UserListComponent } from './user-list/user-list.component';
import { ContainerizeKubernetesDevService } from './services/containerize-kubernetes-dev.service';
import { ContainerizeKubernetesConfig, ContainerizeKubernetesConfigService } from './services/containerize-kubernetes-config.service';

import {MatCardModule} from '@angular/material/card'
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatButtonModule} from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatMenuModule} from '@angular/material/menu';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AddUserDialogComponent } from './add-user-dialog/add-user-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatChipsModule} from '@angular/material/chips';
import {TextFieldModule} from '@angular/cdk/text-field';
import {ConfirmDeleteComponent} from './confirm-delete/confirm-delete.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AppDetailsComponent } from './app-details/app-details.component';
import { ApplicationsComponent } from './applications/applications.component';
import { AddApplicationDialogComponent } from './applications/add-application-dialog/add-application-dialog.component';
import { AddProductDialogComponent } from './add-product-dialog/add-product-dialog.component';
import { IdentityServerComponent } from './identity-server/identity-server.component';
import { ProductFamilyComponent } from './products/product-families.component';
import { DeployCloudBucketDialogComponent } from './applications/deploy-cloud-bucket-dialog/deploy-cloud-bucket-dialog.component';
import { AddMembersDialogComponent } from './applications/add-members-dialog/add-members-dialog.component';

@NgModule({
  declarations: [
    UserListComponent,
    AddUserDialogComponent,
    ConfirmDeleteComponent,
    UserDetailsComponent,
    AppDetailsComponent,
    ApplicationsComponent,
    AddProductDialogComponent,
    IdentityServerComponent,
    ProductFamilyComponent,
    AddApplicationDialogComponent,
    DeployCloudBucketDialogComponent,
    AddMembersDialogComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ContainerizeKubernetesRoutingModule,
    MomentModule.forRoot({
        relativeTimeThresholdOptions: {
          'm': 59
        }
      }),
      MatCardModule,
      MatSnackBarModule,
      MatButtonModule,
      FormsModule,
      ReactiveFormsModule,
      FlexLayoutModule,
      MatDividerModule,
      MatListModule,
      MatExpansionModule,
      MatIconModule,
      MatTableModule,
      MatFormFieldModule,
      MatPaginatorModule,
      MatInputModule,
      MomentModule,
      MatTooltipModule,
      MatProgressSpinnerModule,
      MatMenuModule,
      MatDialogModule,
      MatAutocompleteModule,
      MatRadioModule,
      MatCheckboxModule,
      MatSelectModule,
      MatSortModule,
      MatChipsModule,
      TextFieldModule
      
  ],
  providers: [
    IdentityServerApiService,
    ContainerizeKubernetesDevService,
    ContainerizeKubernetesConfigService
  ],
  bootstrap: [UserListComponent]
})

export class ContainerizeKubernetesModule {
  constructor (@Optional() @SkipSelf() parentModule?: ContainerizeKubernetesModule) {
    if (parentModule) {
      throw new Error(
        'ContainerizeKubernetesModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config: ContainerizeKubernetesConfig): ModuleWithProviders<ContainerizeKubernetesModule> {
    return {
      ngModule: ContainerizeKubernetesModule,
      providers: [
        ContainerizeKubernetesConfigService, {provide: 'config', useValue: config}
      ]
    };
  }
 }

