import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { AddProductDialogComponent } from '../add-product-dialog/add-product-dialog.component';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { ContainerizeKubernetesDevService } from '../services/containerize-kubernetes-dev.service';
export interface PeriodicElement {
  name: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'product-family',
  templateUrl: './product-families.component.html',
  styleUrls: ['./product-families.component.scss']
})
export class ProductFamilyComponent implements OnInit {
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  loading = false;
  displayedColumns: string[] = ['id', 'actions'];
  constructor(
    private prodService: ContainerizeKubernetesDevService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar, 
    ) { }

  async getProds()  {
    const res = await this.prodService.getProdList()
    this.dataSource.data = res.products;
    this.loading = false;
  }

  openAddModal() {
    const addProdDialogRef = this.dialog.open(AddProductDialogComponent);
    addProdDialogRef.afterClosed().subscribe(_ => this.getProds());
  }

  deleteProductFamily(data) {
    const deleteAppDialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        text: `Product ${data.id} will be deleted.`
      }
    });

    deleteAppDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.prodService.deleteProd(data.id).then(() => {
          this.getProds();
          this._snackBar.open(`${data.id} deleted!`, null, {
            duration: 2000,
          });
        }).catch(() => {
          alert('Something went wrong, refresh page and try again. Or contact to technical support.');
        });
      }
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.getProds()
  }

}
