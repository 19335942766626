<h2 mat-dialog-title>New Application. Step 1/2</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="createAppForm">
        <mat-form-field appearance="outline" class="fieldStyle">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Enter name of the app" [formControl]="name" required>
            <mat-error *ngIf="name.hasError('required')">Name is <strong>required</strong></mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="fieldStyle"  *ngIf="!venture">
            <mat-label>Venture</mat-label>
            <mat-select [formControl]="ventureInput" required>
                <mat-option [value]="'No venture'">No venture</mat-option>
                <mat-option [value]="v.id" *ngFor="let v of ventures;">{{v.id}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-checkbox [formControl]="usesdb">Use Database</mat-checkbox>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="openNext()" cdkFocusInitial>Next</button>
</mat-dialog-actions>