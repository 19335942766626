<layout class="layout" *ngIf="isAuth">
    <c-header class="header">
      <div class="logo" routerLink="/">
        <!-- Logo could be changed depending to the product -->
        <img src="assets/images/aspose-logo.svg" width="30px" class="h-6" alt="">
        <span class="ml-2 logo">aspose.app</span>
      </div>
      <button mat-mini-fab color="primary"  class="profile-icon" aria-label="Example icon button with a menu icon" [matMenuTriggerFor]="menu">
        <mat-icon>person</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <div class="profile">
          <span class="profile-avatar">{{shortName}}</span>
          <h3>{{fullName}}</h3>
          <p>{{email}}</p>
          <mat-divider class="profile-divider"></mat-divider>
          <button mat-raised-button (click)="signoutRedirect()">Logout</button>
        </div>  
      </mat-menu>
    </c-header>
  
    <layout>
      <sider [width]="200" collapsible [collapsedWidth]="0">
        <mat-accordion>
          <div *ngFor="let menu of menus">
            <mat-nav-list *ngIf="!menu.subs && (isAdmin || !!menu.isAdmin == !!isAdmin) ">
              <a mat-list-item  [routerLink]="menu.to">
                <mat-icon class="material-icons" aria-hidden="false" aria-label="Example arrow_downwar icon">
                  {{menu.icon}}
                  </mat-icon>
                  {{menu.name}}
              </a>
            </mat-nav-list>
            <mat-expansion-panel *ngIf="menu.subs && menu.subs.length > 0 && (isAdmin || !!menu.isAdmin == !!isAdmin)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="material-icons" aria-hidden="false" aria-label="Example arrow_downwar icon">
                    {{menu.icon}}
                  </mat-icon>
                  {{menu.name}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-nav-list>
                <a mat-list-item  *ngFor="let sub of menu.subs" [routerLink]="sub.to">
                    <mat-icon class="material-icons" *ngIf="sub.icon">
                    {{sub.icon}}
                    </mat-icon>
                    {{sub.name}}
                </a>
             </mat-nav-list>
            </mat-expansion-panel>
          </div>   
        </mat-accordion>
        
      </sider>
      <layout>
        <content style="margin:20px 20px; min-height: calc(100vh - 84px); height: 100%;">
          
            <router-outlet></router-outlet>
        
        </content>
      </layout>
    </layout>
  
  
  
    <c-footer style="text-align: center;">aspose.app ©2021</c-footer>
  </layout>

  <!-- Here you could create loading block -->
  <div *ngIf="!isAuth">

  </div>
  