import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ContainerizeKubernetesDevService } from '../services/containerize-kubernetes-dev.service';
import { AddUserDialogComponent } from '../add-user-dialog/add-user-dialog.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { Sort } from '@angular/material/sort';
export interface PeriodicElement {
  id: string;
  name: string;
  surname: string;
  isadmin?: boolean;
  email: string;
  created: "2020-06-10T08:02:35.984313Z";
}


const ELEMENT_DATA: PeriodicElement[] = [
];


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UserListComponent implements OnInit {

  loading = true;
  total = 0;
  start = 0;
  limit = 10;
  apploading = false;
  stopLoading = false;
  runLoading = false; 
  search = "";
  sortby = null;
  sortd = false;

  activeDevId = null;
  displayedColumns: string[] = ['id', 'name', 'surname', 'email', 'isadmin', 'created', 'actions'];
  appDisplayedColumns: string[] = ['appid', 'name', 'usesdb', 'runtime', 'port', 'conditionCodeStr', 'url'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  expandedElement: PeriodicElement | null;


  appDataSource = new MatTableDataSource([]);
  constructor(private devService: ContainerizeKubernetesDevService, public dialog: MatDialog, private _snackBar: MatSnackBar, private router: Router) {}

  
  async getUsers() {
    const res = await this.devService.getUserList({
      start: this.start, 
      limit: this.limit, 
      search: this.search,
      sortby: this.sortby,
      sortd: this.sortd,
    });
    this.dataSource.data = res.users;
    this.total = res.total;
    this.loading = false;
  }

  ngOnInit() {
    this.getUsers()
  }

  async getApps(devid) {
    const res = await this.devService.getAppList(devid)
    this.appDataSource.data = res.applications;
    this.apploading = false;
    this.stopLoading = false;
    this.runLoading = false; 
  }


  openAddUserDialog(): void {
    const addUserDialogRef = this.dialog.open(AddUserDialogComponent);
    addUserDialogRef.afterClosed().subscribe(_ => this.getUsers());
  }


  reloadData() {
    this._snackBar.open('Data refreshed!', null, {
      duration: 2000,
    });
    this.getUsers();
  }


  openUser(id) {
    this.router.navigate([`users`, id])
  }

  changePage(e) {
    this.start = e.pageIndex * this.limit;
    this.getUsers();
  }

  async changeUserRole(user) {
    await this.devService.updateUserRole(user);
    this.getUsers();
  }

  openInst(instID) {
    this.router.navigate([`applications`, instID])
  }

  clickToUser(element) {

    if(this.expandedElement !== element) {
      this.appDataSource.data = [];
      this.apploading = true;
      this.getApps(element.id)
    }

    return this.expandedElement = this.expandedElement === element ? null : element
  }

  deleteUser(user) {
    const deleteAppDialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        text: `User ${user.email} will be deleted.`
      }
    });
    deleteAppDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.devService.deleteUser(user.id).then(_=>  {
          this.getUsers()
          this._snackBar.open(`${user.email} deleted!`, null, {
            duration: 2000,
          });
        })
        .catch(() => {
          alert('Something went wrong, refresh page and try again. Or contact to technical support.');
        });
      }
    });
  }

  searchFunc() {
    this.getUsers()
  }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.sortby = null;
      this.sortd = false;
      this.getUsers();
      return;
    }

    this.sortd = sort.direction === 'desc';
    this.sortby = sort.active
    this.getUsers();
  }

}
