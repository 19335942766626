import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ContainerizeKubernetesDevService } from '../../services/containerize-kubernetes-dev.service';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, startWith } from 'rxjs/operators';


export interface PeriodicElement {
  id: string;
  name: string;
  surname: string;
  isadmin?: boolean;
  email: string;
}


const ELEMENT_DATA: PeriodicElement[] = [
];


export interface DialogData {
  apps: [];
}



@Component({
  selector: 'app-add-members-dialog',
  templateUrl: './add-members-dialog.component.html',
  styleUrls: ['./add-members-dialog.component.scss']
})
export class AddMembersDialogComponent implements OnInit {
  addMemberForm: FormGroup;
  filteredOptions: Observable<[]>;
  usersloading = true;
  email = new FormControl('');
  users = [];
  apps = []
  displayedColumns: string[] = ['id', 'name', 'surname', 'email', 'actions'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  constructor(
    private devService: ContainerizeKubernetesDevService, 
    public dialog: MatDialog, 
    public dialogRef: MatDialogRef<AddMembersDialogComponent>,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) 
    public data: DialogData,
    fb: FormBuilder
    ) {
      this.apps = data.apps;
      this.addMemberForm = fb.group({
        email: this.email,
      });
    }


  ngOnInit(): void { 
    this.prepareAutoComplite();
  }

  displayFn(user): string {
    return user && user.email ? `${user.email}`: '';
  }


  async prepareAutoComplite() {
    const res = await this.devService.getUserList({});
    this.users = res.users;
    this.filteredOptions = this.email.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? res.users.filter(option => option.name.toLowerCase().indexOf(name.toLowerCase()) === 0) : res.users.slice())
      );
  }

  selectUser() {
    if(typeof this.email.value === "object") {
      this.dataSource.data = [...this.dataSource.data, this.email.value]
      this.email.setValue("");
      return;
    } else {
      for(let i = 0; i < this.users.length; i++) {
        if(this.users[i].email === this.email.value) {
            this.dataSource.data = [...this.dataSource.data, this.users[i]]
            this.email.setValue("");  
          return;
        }
      }
      this._snackBar.open(`User does not exist!`, null, {
        duration: 2000,
      });
    }
  }

  deleteUser(user) {
    let arr = this.dataSource.data
    let index = this.dataSource.data.indexOf(user)
    arr.splice(index, 1)
    this.dataSource.data = arr;
  }
  

  connect(appid, devid) {
    try {
      this.devService.attachUserToApp({appid, devid})
      .then(_=> {
        this.email.setValue("");
        this._snackBar.open(`User was added!`, null, {
          duration: 2000,
        });
      })
    } catch(e) {
      console.log(e.message)
      this._snackBar.open(e.message, null, {
        duration: 2000,
      });
    }
  }

  addUser() {
    if(this.dataSource.data && this.dataSource.data.length > 0) {
      this.apps.forEach(app => this.dataSource.data.map(user => this.connect(app.appID, user.id)))

      this.dialogRef.close();
    }
  }
}
