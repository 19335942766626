import {Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ContainerizeKubernetesDevService} from '../services/containerize-kubernetes-dev.service';
import { DomSanitizer} from '@angular/platform-browser';
import ansiHTML from 'ansi-html';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable, Subscription } from 'rxjs';
import { RoleService } from '../services/role.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from 'containerize-angular-identity-server';
import { DeployCloudBucketDialogComponent } from '../applications/deploy-cloud-bucket-dialog/deploy-cloud-bucket-dialog.component';


export interface PeriodicElement {
  id: string;
  name: string;
  surname: string;
  isadmin?: boolean;
  email: string;
  created: "2020-06-10T08:02:35.984313Z";
}


const ELEMENT_DATA: PeriodicElement[] = [
];


@Component({
  selector: 'app-app-details',
  templateUrl: './app-details.component.html',
  styleUrls: ['./app-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  appID = '';
  logs: any;
  app: any;
  loading = false;
  confirmModal: any;
  interval;
  autoreload = false;
  logging = true;
  loginterval;
  coppyActive = false;
  addMemberForm: FormGroup;
  templatesForm: FormGroup;

  usersloading = true;
  email = new FormControl('');
  template = new FormControl('');

  private subscription: Subscription;
  isAdmin = false;
  showLogs = false;
  filteredOptions: Observable<[]>;
  users = [];
  templates = [];

  displayedColumns: string[] = ['id', 'name', 'surname', 'email', 'actions'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);

  async getUsers() {
    const res = await this.devService.getAppUserList(this.appID);
    this.dataSource.data = res.data.users;
    for(let i = 0; i < res.data.users.length; i++) {
      if(res.data.users[i].id === this.authService.identityClaims["PSP.UserId"]) {
        this.showLogs = true;
        break;
      }
    }
    this.usersloading = false;
  }
  constructor(private route: ActivatedRoute,
              private router: Router,
              private devService: ContainerizeKubernetesDevService,
              private sanitizer: DomSanitizer,
              public dialog: MatDialog, 
              private _snackBar: MatSnackBar,
              private roleService: RoleService,
              private usersDevService: ContainerizeKubernetesDevService,
              private authService: AuthService,
              fb: FormBuilder) {
                this.addMemberForm = fb.group({
                  email: this.email,
                });
                this.templatesForm = fb.group({
                  template: this.template,
                });

               }


  openDeleteAppDialog(): void {
    const deleteAppDialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        text: `Applications will be deleted, it will take some time.`
      }
    });

    deleteAppDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.deleteApps()
      }
    });
  }

  deleteApps() {
    this.devService.deleteAppById(this.app.appID).then(() => {
      this.router.navigate(['/applications']);
      this._snackBar.open('Application removed!', null, {
        duration: 2000,
      });
    }).catch(() => {
      alert('Something went wrong, refresh page and try again. Or contact to technical support.');
    });
  }

  onStop() {
    this.loading = true;
    this.devService.stopApp(this.appID)
      .then(res => setTimeout(() => {
          this.getApp(); this.getLogs();
          this._snackBar.open(`${this.appID} stopped!`, null, {
            duration: 2000,
          });
        }, 3000));
  }

  onRun() {
    this.loading = true;
    this.devService.runApp(this.appID)
      .then(res => setTimeout(() => {
        this.getApp(); this.getLogs();
        this._snackBar.open(`${this.appID} started!`, null, {
          duration: 2000,
        });
      }, 3000));
  }

  getApp() {
    this.devService.getAppByAppID(this.appID).then((res: any) => { 
      if( res.data.code !== -1) {
        this.app = res.data.applications[0]; this.loading = false; 
        if(res.data.applications[0].instances && res.data.applications[0].instances.length >0) {
          this.app = {...this.app, ...res.data.applications[0].instances[0]}
          
          if(this.app.condition === 5) 
            this.logging = false;
        } else {
          this.logs = 'No logs, deploy the app';
        }
      } else {
        this.router.navigate(['applications'])
      }
    });
  }

  getTemplates() {
    this.devService.getTemplates().then((res: any) => { 
      if( res.data.code !== -1) {
        this.templates = res.data.items;
        this.templatesForm.controls['template'].setValue(this.templates[0]);
      }
    });
  }

  downloadTemplate() {
    this.devService.downloadAppTemplate(this.appID, this.template.value).then((res: any) => { 
      if (res.data && res.data.code !== -1) {
        const bytes = Uint8Array.from(atob(res.data.content), (c) => c.charCodeAt(0));
        const blob = new Blob([bytes], {type: res.data.contenttype});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const fileName = res.data.name;
        link.download = fileName;
        link.click();
        link.remove();
      }
    });
  }

  getLogs() {
    this.devService.getLogsByAppID(this.appID).then((res: any) => {
      if (res.data.code === 0) {
        // @ts-ignore
        if(res.data.logs && res.data.logs.length > 0) {
          this.logs = this.sanitizer.bypassSecurityTrustHtml(ansiHTML(res.data.logs.join('<br>')));
          setTimeout(() => {this.scrollToBottom()}, 200)
        }
        else 
          this.logs = res.data.message;
       
      } 
      else {
        this.logs = 'Application stopped';
        if (this.app && this.app.condition && this.app.condition!==6 && this.app.conditionCodeStr !== "Failed" && res.data.code !== -1 ) {
          setTimeout(() => this.getLogs(), 2000);
        } 
        if(this.app && !this.app.condition) {
          this.logs = 'No logs, deploy the app';
        }
        if(res.data.code === -1002) {
          this.logs = res.data.message;
        }
      }
    });
  }

  setAutoReload() {
    if (this.autoreload) {
      this._snackBar.open(`Auto reload stopped!`, null, {
        duration: 2000,
      });
      clearInterval(this.interval);
    } else {
      this._snackBar.open('Auto reload started!', null, {
        duration: 2000,
      });
      this.interval = setInterval(() => {
        this.getApp();

      }, 5000);
    }

    this.autoreload = !this.autoreload;
  }

  reloadData() {
    this.getApp();
    this._snackBar.open('Data reloaded!', null, {
      duration: 2000,
    });
  }

  setLogging() {
    this.logging = !this.logging;
    if (this.logging) {
      this._snackBar.open(`Logs started!`, null, {
        duration: 2000,
      });
    } else {
      this._snackBar.open(`Logs stopped!`, null, {
        duration: 2000,
      });
    }
  }

  coppyLogs() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.logs;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.coppyActive = true;
    setTimeout(() => {
      this.coppyActive = false;
    }, 1000);
  }

  displayFn(user): string {
    return user && user.email ? `${user.email}`: '';
  }


  async prepareAutoComplite() {
    const res = await this.usersDevService.getUserList({});
    this.users = res.users;
    this.filteredOptions = this.email.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? res.users.filter(option => option.name.toLowerCase().indexOf(name.toLowerCase()) === 0) : res.users.slice())
      );
  }

  addUser() {
    if(typeof this.email.value === "object") {
      this.usersDevService.attachUserToApp({appid: this.appID, devid: this.email.value.id})
      .then(_=> {
        this.getUsers()
        this.email.setValue("");
        this._snackBar.open(`User was added!`, null, {
          duration: 2000,
        });
        // this.addMemberForm.reset();
      })
      return;
    } else {
      for(let i = 0; i < this.users.length; i++) {
        if(this.users[i].email === this.email.value) {

          this.usersDevService.attachUserToApp({appid: this.appID, devid: this.users[i].id}).then(_=> this.getUsers())
          return;
        }
      }
      this._snackBar.open(`User does not exist!`, null, {
        duration: 2000,
      });

    }
  }

  openDeployDialog(): void {
    const deployAppDialogRef = this.dialog.open(DeployCloudBucketDialogComponent, {
      data: {
        applicationId: this.app.appID
      }
    });

    deployAppDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.getApp();
        setTimeout(() => this.getLogs(), 3000);
      }
    });
  }

  deleteUser(id) {
    this.usersDevService.unattachUserToApp({devid: id, appid: this.appID}).then(_=> this.getUsers())
  }


  ngOnInit() {

    this.isAdmin = this.roleService.isAdmin;
    this.subscription = this.roleService.roleChanged.subscribe((isAdmin) => {
      this.isAdmin = isAdmin;
      if(this.isAdmin) {
        this.prepareAutoComplite()
      } 
    });
    if(this.isAdmin) {
      this.prepareAutoComplite()
    } 

    this.route.params.subscribe((params: Params) => {
      this.appID = params.appID;
      this.getApp();
      this.getLogs();
      this.getTemplates();
      if(this.isAdmin)
        this.getUsers();
      else
        this.showLogs = true;

    });

   

    this.loginterval = setInterval(() => {
      if (this.logging && this.app && this.app.condition && !(this.app.condition === 6 || this.app.conditionCodeStr === 'Failed' )) {
        this.getLogs();
      }
    }, 5000);

  }

  scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { console.log(err)}                 
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    clearInterval(this.loginterval);
  }



}
