
<mat-card>
    <mat-card-title class="page-card-title">
        Users
        <div class="page-card-actions">
          <mat-form-field  class="search">
            <mat-label>Search</mat-label>
            <input matInput placeholder="Search by id or name" [(ngModel)]="search" (ngModelChange)="searchFunc()">
          </mat-form-field>
          <button class="card-title-button" mat-raised-button  matTooltip="Refresh Data" (click)="reloadData()">
              <mat-icon class="material-icons" aria-hidden="false" aria-label="more_actions">
                  refresh
              </mat-icon> 
          </button>
          <button class="card-title-button" mat-raised-button color="primary" (click)="openAddUserDialog()">New User</button>
        </div>
    </mat-card-title>
    <!-- 
    <button button type="secondary" *ngIf="!autoreload" (click)="setAutoReload()">Turn On Auto Reload</button>
    <button button type="secondary" *ngIf="autoreload" (click)="setAutoReload()">Turn Off Auto Reload</button>
    -->

<div class="overflow">
<table mat-table [dataSource]="dataSource" matSort class="table" (matSortChange)="sortData($event)" multiTemplateDataRows>
  
    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id"> ID </th>
      <td mat-cell *matCellDef="let element" class="colored-link" (click)="openUser(element.id)"> {{element.id}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="name"> First Name </th>
      <td mat-cell *matCellDef="let element">  {{element.name}} </td>
    </ng-container>
     <!-- Surname Column -->
     <ng-container matColumnDef="surname">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="surname"> Last Name </th>
      <td mat-cell *matCellDef="let element" >  {{element.surname}} </td>
    </ng-container>
  
  
    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="email"> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <!-- Role Column -->
    <ng-container matColumnDef="isadmin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="role"> Role </th>
      <td mat-cell *matCellDef="let element" class="role"> 
        {{element.isadmin ? 'Admin' : 'Developer'}}
        <mat-icon class="material-icons role-icon" aria-hidden="false" aria-label="more_actions" [matMenuTriggerFor]="menuRole">
          keyboard_arrow_down
        </mat-icon>
        <mat-menu #menuRole="matMenu">
            <button *ngIf="!element.isadmin" mat-menu-item (click)="changeUserRole(element)">Admin</button>
            <button *ngIf="element.isadmin"  mat-menu-item (click)="changeUserRole(element)">Developer</button>
        </mat-menu>
      </td>
    </ng-container>

    <!-- Created Column -->
    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="created"> Created </th>
      <td mat-cell *matCellDef="let element"> {{element.created | date}} </td>
    </ng-container>


  
    <!-- Actions Column -->
    <ng-container matColumnDef="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element"> 
            <mat-icon (click)="$event.stopPropagation()" class="material-icons" aria-hidden="false" aria-label="more_actions" [matMenuTriggerFor]="menu">
                more_vert
            </mat-icon> 
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="deleteUser(element)">Delete</button>
            </mat-menu>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="example-element-detail"
               [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            
               <table mat-table [dataSource]="appDataSource" class="mat-elevation-z8 table">

                <!-- ID Column -->
                  <ng-container matColumnDef="appid">
                    <th mat-header-cell *matHeaderCellDef> ID </th>
                    <td mat-cell *matCellDef="let element" class="colored-link" (click)="openInst(element.instID)"> {{element.appID}} </td>
                  </ng-container>
                
                  <!-- Name Column -->
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element" (click)="openInst(element.instID)" class="colored-link">  {{element.name}} </td>
                  </ng-container>
                
                  <!-- isUseDB Column -->
                  <ng-container matColumnDef="usesdb">
                    <th mat-header-cell *matHeaderCellDef> Use Database </th>
                    <td mat-cell *matCellDef="let element"> {{element.usesDB ? 'Yes' : 'No'}} </td>
                  </ng-container>

                  <!-- Runtime Column -->
                  <ng-container matColumnDef="runtime">
                    <th mat-header-cell *matHeaderCellDef> Runtime </th>
                    <td mat-cell *matCellDef="let element"> {{element.runtime}} </td>
                  </ng-container>

                  <!-- Port Column -->
                  <ng-container matColumnDef="port">
                    <th mat-header-cell *matHeaderCellDef> Port </th>
                    <td mat-cell *matCellDef="let element"> {{element.port}} </td>
                  </ng-container>

                  <!-- Status Column -->
                  <ng-container matColumnDef="conditionCodeStr">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element"> {{element.conditionCodeStr}} </td>
                  </ng-container>

                  <!-- Url Column -->
                  <ng-container matColumnDef="url">
                    <th mat-header-cell *matHeaderCellDef> Url </th>
                    <td mat-cell *matCellDef="let element"> <a class="link" href="{{element.url}}" color="primary" target="_blank">{{element.url}}</a></td>
                  </ng-container>
                
              
                <tr mat-header-row *matHeaderRowDef="appDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: appDisplayedColumns;"></tr>
               
              </table>
              <mat-spinner *ngIf="apploading" class="spinner"></mat-spinner>
              <h3 class="table-empty--text" *ngIf="!apploading && appDataSource.data && appDataSource.data.length === 0">No Data</h3>
              
          </div>
        </td>
      </ng-container>
    <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="selection.toggle(row)">
    </tr> -->
     <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="clickToUser(element)">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
  <mat-paginator *ngIf="dataSource.data && dataSource.data.length > 0"  [length]="total"
              [pageSize]="limit" (page)="changePage($event)">
</mat-paginator>
  
  <h3 class="table-empty--text" *ngIf="!loading && dataSource.data && dataSource.data.length === 0">No Data</h3>
</div>
</mat-card>