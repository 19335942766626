<h2 mat-dialog-title>Add members for selected apps</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="addMemberForm" (ngSubmit)="selectUser()">
      <mat-form-field appearance="outline" class="fieldStyle">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Enter email" [formControl]="email" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option.email}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="email.hasError('required')">Email is <strong>required</strong></mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary">Select</button>
    </form>
  <div class="overflow">
  <table mat-table [dataSource]="dataSource" class="table">
  
    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let element" class="colored-link"> {{element.id}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> First Name </th>
      <td mat-cell *matCellDef="let element">  {{element.name}} </td>
    </ng-container>
     <!-- Surname Column -->
     <ng-container matColumnDef="surname">
      <th mat-header-cell *matHeaderCellDef> Last Name </th>
      <td mat-cell *matCellDef="let element" >  {{element.surname}} </td>
    </ng-container>
  
  
    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element"> 
            <mat-icon class="material-icons" aria-hidden="false" aria-label="more_actions" [matMenuTriggerFor]="menu">
                more_vert
            </mat-icon> 
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="deleteUser(element)">Unselect</button>
            </mat-menu>
        </td>
      </ng-container>
  
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
  </table>
  <h3 class="table-empty--text" *ngIf="dataSource.data && dataSource.data.length === 0">No Data</h3>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="addUser()" cdkFocusInitial>Add</button>
</mat-dialog-actions>