
import { Injectable, Optional, Inject } from '@angular/core';

export class ContainerizeKubernetesConfig {
    devServiceProviderUrl = 'https://id.staging.k8s.saltov.dynabic.com';
    identityServerUrl = 'https://id.svc.staging.conholdate.cloud';
    venture = null;
}

@Injectable({
  providedIn: 'root'
})
export class ContainerizeKubernetesConfigService {
  private _devServiceProviderUrl = 'https://id.staging.k8s.saltov.dynabic.com';
  private _identityServerUrl = 'https://id.svc.staging.conholdate.cloud';
  private _venture? = null;

  constructor(@Optional() @Inject('config') config?: ContainerizeKubernetesConfig) {
    if (config) { 
      this._devServiceProviderUrl = config.devServiceProviderUrl; 
      this._identityServerUrl = config.identityServerUrl; 
      this._venture = config.venture;
    }
  }

  get devServiceProviderUrl() {
    return this._devServiceProviderUrl;
  }

  get identityServerUrl() {
    return this._identityServerUrl;
  }
  get venture() {
    return this._venture;
  }
}





