import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
  text: string;
}
@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit {
  text = '';
  constructor( @Inject(MAT_DIALOG_DATA) 
  public data: DialogData) {
    this.text = data.text
  }

  ngOnInit(): void {
  }

}
