<h2 mat-dialog-title>Deploy Instance. Step 2/2</h2>
<mat-dialog-content class="mat-typography modal-width">
  <!-- <h3>To deplay a application first name it</h3>
  <p>Learn one way to build applications with Angular and reuse your code and abilities to build
    apps for any deployment target. For web, mobile web, native mobile and native desktop.</p> -->

    <mat-radio-group class="radio" aria-label="Select an option" [(ngModel)]="radioValue" (change)="onChange()">
        <mat-radio-button value="2">Docker Image</mat-radio-button>
        <mat-radio-button value="1">Git repository</mat-radio-button>
    </mat-radio-group>
    <form [formGroup]="createCloudBucketForm" >
        <div [hidden]="radioValue!=='1'">
            <mat-form-field appearance="outline" class="fieldStyle">
                <mat-label>Repository</mat-label>
                <input matInput placeholder="Enter link to repository" [formControl]="repository" [required]="radioValue==='1'">
                <mat-error *ngIf="repository.hasError('required')">Repository is <strong>required</strong></mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="fieldStyle">
                <mat-label>Entrypoint</mat-label>
                <input matInput placeholder="Enter entrypoint" [formControl]="entrypoint" [required]="radioValue==='1'">
                <mat-error *ngIf="entrypoint.hasError('required')">Entrypoint is <strong>required</strong></mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="fieldStyle">
                <mat-label>BuildRoot</mat-label>
                <input matInput placeholder="Enter BuildRoot" [formControl]="buildRoot">
                <mat-error *ngIf="buildRoot.hasError('required')">BuildRoot is <strong>required</strong></mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="fieldStyle">
                <mat-label>Runtime</mat-label>
                <mat-select [formControl]="runtime" [required]="radioValue==='1'">
                    <mat-option [value]="runtime.runtimeId" *ngFor="let runtime of runtimes;">{{runtime.description}}</mat-option>
                </mat-select>
                <mat-error *ngIf="runtime.hasError('required')">Runtime is <strong>required</strong></mat-error>
            </mat-form-field>
        </div>
        <div [hidden]="radioValue==='1'">
            <mat-form-field appearance="outline" class="fieldStyle">
                <mat-label>Image</mat-label>
                <input matInput placeholder="Enter link to image" [formControl]="image" [required]="radioValue==='2'">
                <mat-error *ngIf="image.hasError('required')">Image <strong>required</strong></mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="fieldStyle">
                <mat-label>Host OS</mat-label>
                <mat-select [formControl]="hostOS" [required]="radioValue==='1'">
                    <mat-option [value]="'linux'">Linux</mat-option>
                    <mat-option [value]="'windows'">Windows</mat-option>
                </mat-select>
                <mat-error *ngIf="hostOS.hasError('required')">Host OS <strong>required</strong></mat-error>
            </mat-form-field>
        </div>

        <mat-form-field appearance="outline" class="fieldStyle">
            <mat-label>Port</mat-label>
            <input matInput placeholder="Listen port" [formControl]="port" required>
            <mat-error *ngIf="port.hasError('required')">Port is <strong>required</strong></mat-error>
        </mat-form-field>
        <input matInput hidden [formControl]="appid">
    </form>



    <form>
        <hr>
    <h3>Envvars</h3>
        <div 
        fxLayout="row"
        fxLayout.xs="column" 
        fxLayoutAlign="space-between" 
        gdColumns="calc(50% - 10px) calc(50% - 10px)"
        gdColumns.xs="100%" 
        gdGap="20px"
        gdGap.xs="0"
        class="formGroup"
        *ngFor="let envvar of envvars; let i = index"
        >
            <mat-form-field appearance="outline" class="fieldStyle">
                <mat-label>Key</mat-label>
                <input matInput placeholder="Key" [(ngModel)]="envvar.key" name="key{{i}}">
                <mat-error *ngIf="port.hasError('required')">Port is <strong>required</strong></mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="fieldStyle">
                <mat-label>Value</mat-label>
                <input matInput placeholder="Value" [(ngModel)]="envvar.value" name="value{{i}}">
                <mat-error *ngIf="port.hasError('required')">Port is <strong>required</strong></mat-error>
            </mat-form-field>
            <mat-icon class="remove-icon" (click)="removeEnvvar(i)">close</mat-icon>
        </div>
    </form>
        <button mat-raised-button type="button" (click)="addEnvvar()" class="button-margin--bottom">Add envvar</button>
        <hr>
        <h3>Health checks</h3>
    <div *ngFor="let type of probTypes">
        <mat-checkbox  [(ngModel)]="probes[type].active" class="example-margin">{{type}}</mat-checkbox>
        <div *ngIf="probes[type].active">
            <h4>HTTP</h4>
            <div 
            fxLayout="row"
            fxLayout.xs="column" 
            fxLayoutAlign="space-between" 
            gdColumns="calc(50% - 10px) calc(50% - 10px)"
            gdColumns.xs="100%" 
            gdGap="20px"
            gdGap.xs="0"
            class="formGroup"
            >
                <mat-form-field appearance="outline" class="fieldStyle">
                    <mat-label>Path</mat-label>
                    <input matInput placeholder="Enter path" [(ngModel)]="probes[type].http.path" required>
                    <mat-error *ngIf="probes[type].active && probes[type].http.path === ''">Path is <strong>required</strong></mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="fieldStyle">
                    <mat-label>Port</mat-label>
                    <input matInput placeholder="Listen port" [(ngModel)]="probes[type].http.port">
                </mat-form-field>
            </div>
            <h3>Headers</h3>
            <div 
            fxLayout="row"
            fxLayout.xs="column" 
            fxLayoutAlign="space-between" 
            gdColumns="calc(50% - 10px) calc(50% - 10px)"
            gdColumns.xs="100%" 
            gdGap="20px"
            gdGap.xs="0"
            class="formGroup"
            *ngFor="let header of probes[type].http.headers; let i = index"
            >
                <mat-form-field appearance="outline" class="fieldStyle">
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="Name" [(ngModel)]="header.name" name="name{{i}}">
                </mat-form-field>
                <mat-form-field appearance="outline" class="fieldStyle">
                    <mat-label>Value</mat-label>
                    <input matInput placeholder="Value" [(ngModel)]="header.value" name="value{{i}}">
                </mat-form-field>
                <mat-icon class="remove-icon" (click)="removeHeader(type, i)">close</mat-icon>
            </div>
            <button mat-raised-button type="button" (click)="addHeader(type)" class="button-margin--bottom">Add header</button>
            <div 
            fxLayout="row"
            fxLayout.xs="column" 
            fxLayoutAlign="space-between" 
            gdColumns="calc(50% - 10px) calc(50% - 10px)"
            gdColumns.xs="100%" 
            gdGap="0 20px"
            gdGap.xs="0"
            class="formGroup"
            >
                <mat-form-field appearance="outline" class="fieldStyle">
                    <mat-label>Initial Delay Seconds</mat-label>
                    <input matInput placeholder="Enter initialDelaySeconds" type="number" [(ngModel)]="probes[type].initialDelaySeconds">
                </mat-form-field>
                <mat-form-field appearance="outline" class="fieldStyle">
                    <mat-label>Period Seconds</mat-label>
                    <input matInput placeholder="Enter periodSeconds" type="number" [(ngModel)]="probes[type].periodSeconds">
                </mat-form-field>
                <mat-form-field appearance="outline" class="fieldStyle">
                    <mat-label>Timeout Seconds</mat-label>
                    <input matInput placeholder="Enter timeoutSeconds" type="number" [(ngModel)]="probes[type].timeoutSeconds">
                </mat-form-field>
                <mat-form-field appearance="outline" class="fieldStyle">
                    <mat-label>Success Threshold</mat-label>
                    <input matInput placeholder="Enter successThreshold" type="number" [(ngModel)]="probes[type].successThreshold">
                </mat-form-field>
                <mat-form-field appearance="outline" class="fieldStyle">
                    <mat-label>Failure Threshold </mat-label>
                    <input matInput placeholder="Enter failureThreshold" type="number" [(ngModel)]="probes[type].failureThreshold">
                </mat-form-field>
            </div>
        </div>

    </div>
<hr>
    <h3>Proxy</h3>
    <mat-form-field appearance="outline" class="fieldStyle" [style.fontSize]="16">
        <mat-label>Configuration Nginx</mat-label>
        <textarea 
            [(ngModel)]="proxy.configuration.nginx"
            matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="20"></textarea>
    </mat-form-field>
    <h4>Entrypoints</h4>
    <div 
    fxLayout="row"
    fxLayout.xs="column" 
    fxLayoutAlign="space-between" 
    gdColumns="calc(30% - 10px) calc(70% - 10px)"
    gdColumns.xs="100%" 
    gdGap="20px"
    gdGap.xs="0"
    class="formGroup"
    *ngFor="let point of proxy.entrypoints; let i = index"
    >
        <!-- <mat-form-field appearance="outline" class="fieldStyle">
            <mat-label>ID</mat-label>
            <input matInput placeholder="ID, example: pdf_convertion, words" [(ngModel)]="point.name" name="name{{i}}">
        </mat-form-field>
        <mat-form-field appearance="outline" class="fieldStyle">
            <mat-label>Human readable name</mat-label>
            <input matInput placeholder="Human readable name" [(ngModel)]="point.description" name="desc{{i}}">
        </mat-form-field> -->
        <div class="entrypoint-host">
            <mat-form-field appearance="outline" class="fieldStyle">
                <mat-label>Host</mat-label>
                <input matInput placeholder="Host" [(ngModel)]="point.host" name="host{{i}}">
            </mat-form-field>
            <mat-icon class="remove-icon remove-entrypoint" (click)="removeEntrypoint(i)" matTooltip="Remove host with paths">close</mat-icon>
        </div>
        
        <div>
            <div 
                fxLayout="row"
                fxLayout.xs="column" 
                fxLayoutAlign="space-between" 
                gdColumns="calc(50% - 10px) calc(50% - 10px)"
                gdColumns.xs="100%" 
                gdGap="20px"
                gdGap.xs="0"
                class="formGroup"
                *ngFor="let path of point.paths; let j = index"
            >
                <mat-form-field appearance="outline" class="fieldStyle">
                    <mat-label>Path from</mat-label>
                    <input matInput placeholder="Host" [(ngModel)]="path.from" name="path-from-{{i}}{{j}}">
                </mat-form-field>
                <mat-form-field appearance="outline" class="fieldStyle">
                    <mat-label>Path to</mat-label>
                    <input matInput placeholder="Host" [(ngModel)]="path.to" name="path-to-{{i}}{{j}}">
                </mat-form-field>
                <mat-icon class="remove-icon" (click)="removeEntrypointPath(i, j)" matTooltip="Remove path">close</mat-icon>
            </div>
            <button mat-raised-button type="button" (click)="addEntrypointPath(i)" class="button-margin--bottom">Add path</button>
        </div>
       
      
    </div>
    <button mat-raised-button type="button" (click)="addEntrypoint()" class="button-margin--bottom">Add entrypoint</button>
    

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="save()" cdkFocusInitial>Deploy</button>
</mat-dialog-actions>