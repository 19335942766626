import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {UserListComponent } from './user-list/user-list.component';
import { AuthGuard } from 'containerize-angular-identity-server';
import { UserDetailsComponent } from './user-details/user-details.component';
import {IdentityServerComponent} from './identity-server/identity-server.component'
import { ApplicationsComponent } from './applications/applications.component';
import { AppDetailsComponent } from './app-details/app-details.component';
import { ProductFamilyComponent } from './products/product-families.component';
const routes: Routes = [
    {
        path: 'users',
        component: UserListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'users/:userId',
        component: UserDetailsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'api-credentials',
        component: IdentityServerComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'applications',
        component: ApplicationsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'applications/:appID',
        component: AppDetailsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'products',
        component: ProductFamilyComponent,
        canActivate: [AuthGuard],
      },
      { path: '', redirectTo: '/applications', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContainerizeKubernetesRoutingModule { }
