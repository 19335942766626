import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ContainerizeKubernetesDevService } from '../../services/containerize-kubernetes-dev.service';
import { ContainerizeKubernetesConfigService } from '../../services/containerize-kubernetes-config.service';
import { RoleService } from '../../services/role.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-add-application-dialog',
  templateUrl: './add-application-dialog.component.html',
  styleUrls: ['./add-application-dialog.component.scss']
})
export class AddApplicationDialogComponent implements OnInit {
  createAppForm: FormGroup;
  name = new FormControl('');
  usesdb = new FormControl(false);
  loading = false;
  ventures = []
  venture = null;
  ventureInput = new FormControl(null);
  isAdmin = false;
  private subscription: Subscription;
  constructor(
    private devService: ContainerizeKubernetesDevService, 
    public dialog: MatDialog, 
    private roleService: RoleService,
    public dialogRef: MatDialogRef<AddApplicationDialogComponent>,
    private configs: ContainerizeKubernetesConfigService,
    fb: FormBuilder
    ) {
      this.venture = this.configs.venture
      let formData: {
        name: FormControl,
        usesdb: FormControl,
        venture?: FormControl
      } = {
        name: this.name,
        usesdb: this.usesdb
      }
      if(!this.venture) formData.venture = this.ventureInput;

      this.createAppForm = fb.group(formData);
    }


    async getVentures() {
      this.ventures = await this.devService.getVentures();
    }

  ngOnInit(): void { 
      this.isAdmin = this.roleService.isAdmin;
      console.log( this.isAdmin )
      if( this.isAdmin) this.getVentures();
  }

  openNext() {
    if(this.createAppForm.valid && !this.loading) {
      this.loading = true;
      let sendData = {...this.createAppForm.value}
      if(this.createAppForm.value.venture === 'No venture')  sendData.venture = null
      if(this.venture) sendData.venture = this.venture
      this.devService.register(sendData).then(res => {
        const {data} = res
        const {message} = data
        const id = message.slice(message.lastIndexOf(":") + 3, message.lastIndexOf("'"))
        this.dialogRef.close(id)
        this.loading = false;
      })
    }
    
  }

}
