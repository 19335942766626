import { Component, OnInit } from '@angular/core';
import { IdentityServerApiService } from './identity-server-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-api-credentials',
  templateUrl: './identity-server.component.html',
  styleUrls: ['./identity-server.component.scss']
})
export class IdentityServerComponent implements OnInit {
  conholdateCloudIdentityToken = '';
  coppyActive = false;
  tokenInfo;
  expDate;
  constructor(private ccIdentityService: IdentityServerApiService,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getCCIdentityToken();
  }

  getCCIdentityToken() {
    if(!this.ccIdentityService.getTokenFromLocalStorage()) {
      this.ccIdentityService.getToken()
        .then((res) => {
          this.ccIdentityService.setTokenToLocalStorage(res.data);
          this.conholdateCloudIdentityToken = res.data;
          this.tokenInfo = this.ccIdentityService.decodeToken(this.conholdateCloudIdentityToken); // decode token
          // @ts-ignore
          this.expDate = new Date(this.tokenInfo.exp * 1000);
        });
    } else {
      this.conholdateCloudIdentityToken = this.ccIdentityService.getTokenFromLocalStorage();
      this.tokenInfo = this.ccIdentityService.decodeToken(this.conholdateCloudIdentityToken); // decode token
      // @ts-ignore
      this.expDate = new Date(this.tokenInfo.exp * 1000);
    }
  }



  isExpired() {
    // @ts-ignore
    if (this.expDate > new Date()) {
      return true;
    } else { return false; }
  }

  refreshConholdateCloudIdentityToken() {
    this.ccIdentityService.refreshToken()
      .then((res) => {
        this.ccIdentityService.setTokenToLocalStorage(res.data);
        this.conholdateCloudIdentityToken = res.data;
        this.tokenInfo = this.ccIdentityService.decodeToken(this.conholdateCloudIdentityToken); // decode token
        // @ts-ignore
        this.expDate = new Date(this.tokenInfo.exp * 1000);

        this._snackBar.open('New access token generated!', null, {
          duration: 2000,
        });
      });
  }

  /* To copy any Text */
  copyText() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.conholdateCloudIdentityToken;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.coppyActive = true;
    setTimeout(() => {
      this.coppyActive = false;
    }, 1000);
  }


}
